/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import * as $ from 'jquery';

import Decorators from '../Utility/Decorators';
import LoggableBase from '../Utility/Log/LoggableBase';
import IContextual, { TrySetContext } from '../Utility/Observable/IContextual';

export default abstract class ViewModelBase extends LoggableBase
{
    protected $context: JQuery<HTMLElement> = null;

    constructor(context: HTMLElement, logKey: string)
    {
        super(logKey);
        this.$context = $(context);
        Decorators.PerformBinding(this);
    }

    public IsVisible(): boolean
    {
        return this.$context.is(':visible');
    }

    protected configureContext(): void
    {
        this.applyContext(this);
    }

    private applyContext(obj: Object): void
    {
        Object.keys(obj).forEach(key => TrySetContext(obj[key], this.$context));
        this.onContext(this.$context);
    }

    protected abstract onContext($context: JQuery<HTMLElement>): void;
}
