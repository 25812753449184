/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import * as $ from 'jquery';

import EndPoints from '../../../common/EndPoints';
import IServerSettings from './IServerSettings';
import ServiceBase from './ServiceBase';

export default class SettingsService extends ServiceBase
{
    constructor(hostUrl: string)
    {
        super('SettingsService', hostUrl);
    }

    public GetSettings(name: string): Promise<IServerSettings>
    {
        return new Promise((resolve, reject) =>
        {
            this.getSettingsRequest(name)
                .then(result => resolve(result))
                .catch(err => reject(err.responseText));
        });
    }

    public SaveSettings(name: string, value: string): Promise<boolean>
    {
        return new Promise((resolve, reject) =>
        {
            this.saveSettingsRequest(name, value)
                .then(result => resolve(result))
                .catch(err => reject(err.responseText));
        });
    }

    private getSettingsRequest(name: string): Promise<IServerSettings>
    {
        return new Promise((resolve, reject) =>
        {
            this.settingsRequest(this.hostUrl, { Name: name }, resolve, reject);
        });
    }

    private saveSettingsRequest(name: string, value: string): Promise<boolean>
    {
        return new Promise((resolve, reject) =>
        {
            const data: Object =
            {
                Name: name,
                Value: value
            };

            this.settingsRequest(this.hostUrl, data, resolve, reject);
        });
    }

    private settingsRequest(
        hostUrl: string,
        params: any,
        resolve: (args: any) => void,
        reject: (args: any) => void): void
    {
        $.post(`${hostUrl}${EndPoints.PORTAL_SETTINGS}`, params,
            result => this.resultHandler(result, resolve, reject))
        .fail((req, status, err) => this.failHandler(status, err, reject));
    }
}
