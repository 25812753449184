/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import Constants from '../../../common/Constants';
import IPrizeCycleParams, { IBonusPrize } from '../../../common/IPrizeCycleParams';
import { TestStatus } from '../../../common/TestStatus';
import GameList from '../GameList';
import PrizeCycleService from '../Server/PrizeCycleService';
import LinkedList from '../Utility/List/LinkedList';
import { LogColor } from '../Utility/Log/Logger';
import IGameInfoVM from '../ViewModel/IGameInfoVM';
import TestInstanceBase from './TestInstanceBase';
import TestManagerBase, { ITestManagerCriteria } from './TestManagerBase';
import TestPrizeInstance from './TestPrizeInstance';

export default class TestPrizeManager extends TestManagerBase
{
    private _prizeService: PrizeCycleService = null;
    private _prizes: IPrizeCycleParams[] = [];
    private _standardPrizes: IPrizeCycleParams[] = [];
    private _bonusPrizes: IBonusPrize[] = [];

    private _betConfigsIds: number[];
    private _betConfigs: LinkedList<number>;

    private _prizeHash: any = {};
    private _prizeIndexHash: any = {};

    private _reelFetches: Promise<void>[] = [];

    constructor(gameList: GameList, criteria: ITestManagerCriteria)
    {
        super(gameList, criteria);

        this._prizeService = criteria.PrizeService;
    }

    public BeginTesting(): void
    {
        if (this.userOptionVM.GameCount !== 1)
        {
            this.Error('Must have exactly one game in order to prize cycle.');
            return;
        }

        if (!this.userOptionVM.Paytable)
        {
            this.Error('Paytable field is required for prize cycling.');
            return;
        }

        if (!this.userOptionVM.IncludeStandard && !this.userOptionVM.IncludeBonus)
        {
            this.Error('Must include at least standard or bonus.');
            return;
        }

        if (!this.tryParseBetConfigIds()) { return; }
        this.createPrizeCriteria();

        this.Log(`Deleting Test Results for Key: [ ${this.userOptionVM.TestKey} ]`);
        this.TestingService.DeleteTestResults(this.userOptionVM.TestKey);

        this.hideStart();

        this.testOptionVM.ToggleLogFilter(true);
        this.Log('Starting Prize Cycling..');
        this.userOptionVM
                .DisableFilter()
                .ToggleUserOptions(false, true);

        let username: string = this.userOptionVM.UserId;
        const complete: Function = (arg: string[] = null) =>
        {
            if (arg && arg[0]) { username = arg[0]; }
            this.Log(`Proceeding with user: [${username}]`);

            if (arg)
            {
                this.configureGameInfos(arg);
            }
            else
            {
                this.configureGameInfos();
            }

            this.startPrizeSearch();
        };

        if (this.userOptionVM.UserId.indexOf(Constants.DEFAULT_TEMPLATE) > -1)
        {
            this.testingService
                    .GetUsers(this.userOptionVM.UserId, this.getTotalUsers())
                    .then(usernames => complete(usernames));
        }
        else
        {
            complete();
        }
    }

    public GetNextPrizeForce(): IPrizeCycleParams
    {
        let prize: IPrizeCycleParams;

        if (this.userOptionVM.IncludeBonus)
        {
            if (this._bonusPrizes.length)
            {
                prize = this._bonusPrizes.pop();
            }
        }

        if (!this.userOptionVM.IncludeStandard) { return  null; }
        if (!prize) { prize = this._standardPrizes.pop(); }
        if (!prize) { return null; }

        if (!this.userOptionVM.IncludeReelStop)
        {
            prize.ReelStop = -1;
        }

        if (!this.userOptionVM.IncludeBranch)
        {
            prize.BranchIndex = -1;
            if (!this.userOptionVM.IncludeReelStop)
            {
                delete (prize as IBonusPrize).BonusList;
            }
        }

        const prizeIndexHash: object = this._prizeIndexHash[prize.BetConfigId];
        const hashKey: string = this.getPrizeHash(prize);

        const hasPrizeIndex: boolean = prize.PrizeIndex in prizeIndexHash;
        const existing: IPrizeCycleParams = this._prizeHash[hashKey];

        if (existing)
        {
            if (!hasPrizeIndex && prize.BranchIndex === 0)
            {
                prize.BranchIndex = -1;
            }
            else
            {
                if (this.isBonus(prize))
                {
                    this.UpdateTestResult(prize, TestStatus.Skipped,
                        `Prize already exists: [ ${this.getPrizeString(existing)} ]`);
                }
                return this.GetNextPrizeForce();
            }
        }

        this._prizeHash[hashKey] = prize;
        prizeIndexHash[prize.PrizeIndex] = null;
        return prize;
    }

    public AddPrize(prize: IPrizeCycleParams): void
    {
        const hash: string = this.getPrizeHash(prize);
        const bonusPrize: IBonusPrize = prize as IBonusPrize;

        if (this._prizeHash[hash] === prize) { delete this._prizeHash[hash]; }

        if (bonusPrize.BonusList != null)
        {
            this._bonusPrizes.push(bonusPrize);
        }
        else
        {
            this._standardPrizes.push(prize);
        }
    }

    protected getTestInstance(gameInfo: IGameInfoVM, username: string): TestInstanceBase
    {
        return new TestPrizeInstance(this, gameInfo,
            {
                Color: this.colorPalette.Next as LogColor,
                TestCount: gameInfo.TestCount,
                UserId: username,
                DtMod: gameInfo.DtMod
            });
    }

    private createPrizeCriteria(): IPrizeCycleParams
    {
        return {
            GameName: this.userOptionVM.TestGameId,
            PaytableId: this.userOptionVM.Paytable,
            BetConfigId: this._betConfigs.First.Value
        };
    }

    private tryParseBetConfigIds(): boolean
    {
        const idsString: string = this.userOptionVM.BetConfigIds.trim();
        if (!idsString)
        {
            this.Error('No Bet Config Ids set!');
            return false;
        }

        this._betConfigsIds = [];
        const ids: string[] = idsString.split(',');
        ids.forEach(id =>
            {
                try
                {
                    this._betConfigsIds.push(Number.parseInt(id, 10));
                }
                catch (err)
                {
                    this.Error(err);
                    return false;
                }
            });

        if (!this._betConfigsIds.length)
        {
            this.Error('No Bet Config Ids set!');
            return false;
        }
        this._betConfigs = new LinkedList<number>(this._betConfigsIds);
        return true;
    }

    private getPrizeHash(prize: IPrizeCycleParams): string
    {
        return this.isBonus(prize)
            ? this.getBonusPrizeHash(prize as IBonusPrize)
            : this.getStandardPrizeHash(prize);
    }

    private isBonus(prize: IPrizeCycleParams): boolean
    {
        return (prize as IBonusPrize).BonusList != null;
    }

    private getStandardPrizeHash(prize: IPrizeCycleParams): string
    {
        return `S-${prize.BetConfigId}-${prize.PrizeIndex}-${prize.BranchIndex}-${prize.ReelStop}`;
    }

    private getBonusPrizeHash(prize: IBonusPrize): string
    {
        return `B-${prize.BetConfigId}-${prize.BonusList.sort()}`;
    }

    private reset(): void
    {
        this._prizes = [];
        this._prizeHash = {};
    }

    private startPrizeSearch(): void
    {
        this.reset();

        const prize: IPrizeCycleParams = this.createPrizeCriteria();

        if (!this._prizeIndexHash[prize.BetConfigId])
        {
            this._prizeIndexHash[prize.BetConfigId] = {};
        }

        this._prizeService
                .GetPrizeIndices(
                    prize.GameName,
                    prize.PaytableId,
                    prize.BetConfigId
                )
                .then(result =>
                {
                    if (isNaN(result))
                    {
                        this.Error(`Invalid result: ${result}`);
                        return;
                    }

                    this.Log(`BET CONFIG: [ ${prize.BetConfigId} ] TOTAL PRIZES: [ ${result} ]`);
                    this.prizeIndexHandler(result);
                });
    }

    private prizeIndexHandler(prizeIndex: number): void
    {
        prizeIndex--;
        if (prizeIndex > this.userOptionVM.MaxPrizeIndex)
        {
            prizeIndex = this.userOptionVM.MaxPrizeIndex;
        }

        const prize: IPrizeCycleParams = this.createPrizeCriteria();
        prize.PrizeIndex = prizeIndex;

        this._reelFetches.push(
        this._prizeService
                .GetTranslatorIndex(
                    prize.GameName,
                    prize.PaytableId,
                    prize.BetConfigId,
                    prize.PrizeIndex
                )
                .then(translator => this.prizeTranslatorHandler(translator, prize)));

        if (!this.isLast(prize))
        {
            this.prizeIndexHandler(prize.PrizeIndex);
        }
    }

    private prizeTranslatorHandler(branchIndex: number, prize: IPrizeCycleParams): void
    {
        prize.BranchIndex = branchIndex - 1;

        this._reelFetches.push(
            this._prizeService
                .GetReelStopIndex(
                    prize.GameName,
                    prize.PaytableId,
                    prize.BetConfigId,
                    prize.PrizeIndex,
                    prize.BranchIndex
                )
                .then(reelStop =>
                    {
                        prize.ReelStop = reelStop - 1;
                        this._prizes.push(prize);

                        // tslint:disable-next-line:max-line-length
                        this.Log(`COMPLETE RESULT: [ ${this.getPrizeString(prize)} ]`);
                    }));

        if (prize.BranchIndex > 0)
        {
            this.prizeTranslatorHandler(prize.BranchIndex, Object.assign({}, prize));
        }

        if (this.isLast(prize) && prize.BranchIndex === 0)
        {
            Promise.all(this._reelFetches).then(() => this.beginBonusListFetch());
        }
    }

    private getPrizeString(prize: IPrizeCycleParams): string
    {
        // tslint:disable-next-line:max-line-length
        return `Bet: ${prize.BetConfigId}, Prize: ${prize.PrizeIndex}, Branch: ${prize.BranchIndex}, ReelStop: ${prize.ReelStop}`;
    }

    private isLast(prize: IPrizeCycleParams): boolean
    {
        return prize.PrizeIndex === 0 || prize.PrizeIndex === this.userOptionVM.MinPrizeIndex;
    }

    private beginBonusListFetch(): void
    {
        const fetches: Promise<void>[] = [];

        for (let i: number = this._prizes.length - 1; i > -1; i--)
        {
            const prize: IPrizeCycleParams = Object.assign({}, this._prizes[i]);
            fetches.push(
                this._prizeService
                    .GetBonusScripts(
                        prize.GameName,
                        prize.PaytableId,
                        prize.BetConfigId,
                        prize.PrizeIndex,
                        prize.BranchIndex
                    )
                    .then(result =>
                    {
                        const bonusList: number[][] = result && result.BonusList ? JSON.parse(result.BonusList) : null;

                        if (result.Id != null)
                        {
                            prize.Id = result.Id;
                        }

                        if (!bonusList || bonusList.length < 1)
                        {
                            this.addStandardPrize(prize);
                            this.Log(`PRIZE: [ ${prize.Id} ] | NO BONUS!`);
                            return;
                        }

                        this.addBonusPrize(prize, bonusList);
                    })
                    .catch(this.Error));
        }

        Promise.all(fetches).then(() =>
        {
            if (this._betConfigs.Advance().First != null)
            {
                this.startPrizeSearch();
                return;
            }

            if (!this.userOptionVM.IncludeBranch && !this.userOptionVM.IncludeReelStop)
            {
                this._bonusPrizes = this._bonusPrizes.concat(this._standardPrizes as IBonusPrize[]);
                this._standardPrizes = [];
            }
            this._bonusPrizes.sort(this.prizeCompare.bind(this));
            this._standardPrizes.sort(this.prizeCompare.bind(this));
            this.triggerFirstTest();
        });
    }

    private prizeCompare(a: IPrizeCycleParams, b: IPrizeCycleParams): number
    {
        let amount: number;

        // Prize
        amount = a.PrizeIndex - b.PrizeIndex;
        if (amount) { return amount; }

        // Branch
        amount = a.BranchIndex - b.BranchIndex;
        if (amount) { return amount; }

        // ReelStop
        amount = a.ReelStop - b.ReelStop;
        if (amount) { return amount; }

        // BetConfig
        return b.BetConfigId - a.BetConfigId;
    }

    private addStandardPrize(prize: IPrizeCycleParams): void
    {
        this._standardPrizes.push(prize);
        while (prize.ReelStop > 0)
        {
            prize = Object.assign({}, prize);
            if (this.userOptionVM.ReelStopSkip > 0)
            {
                prize.ReelStop -= this.userOptionVM.ReelStopSkip;
                if (prize.ReelStop < 0) { prize.ReelStop = 0; }
            }
            else
            {
                prize.ReelStop--;
            }

            this._standardPrizes.push(prize);
        }
    }

    private addBonusPrize(prize: IPrizeCycleParams, bonusList: number[][]): void
    {
        const bonus: number[] = bonusList[0][0] !== -1
            ? bonusList[0]
            : bonusList[1];

        const bonusPrize: IBonusPrize = Object.assign({}, prize as IBonusPrize);
        bonusPrize.ReelStop = -1;
        bonusPrize.BonusList = bonus;

        this._bonusPrizes.push(bonusPrize);
        this.Log(`PRIZE: [ ${prize.Id} ] | BONUS RESULT: [ ${bonus.toString().slice(0, 65)} ].`);
    }
}
