/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import LoggableBase from './Utility/Log/LoggableBase';
import IUserOptionVM from './ViewModel/IUserOptionVM';
import CookieHelper from './Utility/CookieHelper';

export default class Login extends LoggableBase
{
    public LogKey: symbol = Symbol(Login.name);

    private cookies = new CookieHelper();
    
    private button = document.getElementById('loginButton');

    private username = 'everi'
    private password = 'everi123'

    constructor(baseUrl: string)
    {
        super('Login');
        
        //if cookie login is there, push to /launch
        let cookieUser = this.cookies.getCookie('login');
        if (cookieUser != '') {
            window.location.href = `${baseUrl}/launch`;
        }

        //click listener
        this.button.addEventListener("click", () => this.loginButton(baseUrl));

        //enter button listener
        window.addEventListener("keyup", function (event) {
                event.preventDefault();
                if (event.code === 'Enter') {
                    document.getElementById('loginButton').click(); 
                }
            });

    }

    public loginButton(baseUrl): any {
        let usernameInput = (document.getElementById('username') as HTMLInputElement).value;
        let passwordInput = (document.getElementById('password') as HTMLInputElement).value;
        
        if (usernameInput === this.username && passwordInput === this.password) {
            this.cookies.setCookie('login', 'true', 30);
            window.location.href = `${baseUrl}/launch`;
        }
    }
    
}
