/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import IListItem from "./IListItem";

export default class ListItem<T> implements IListItem<T>
{
    private _next: IListItem<T>;
    public get Next(): IListItem<T> { return this._next; }
    public set Next(value: IListItem<T>) { this._next = value; }

    private _value: T;
    public get Value(): T { return this._value; }

    constructor(value: T, next: IListItem<T> = null)
    {
        this._value = value;
        this._next = next;
    }
}
