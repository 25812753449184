/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import IList from './IList';
import IListItem from './IListItem';
import ListItem from './ListItem';

export default class LinkedList<T> implements IList<T>
{
    protected first: IListItem<T>;
    public get First(): IListItem<T> { return this.first; }

    protected last: IListItem<T>;

    constructor(items: T[])
    {
        const maxIndex: number = items.length - 1;
        let lastItem: IListItem<T>;
        items.forEach((item, index) =>
            {
                const newItem: IListItem<T> = new ListItem(item);
                if (lastItem)
                {
                    lastItem.Next = newItem;
                }
                else
                {
                    this.first = newItem;
                }

                if (index === maxIndex)
                {
                    this.last = newItem;
                }
                lastItem = newItem;
            });
    }

    public Advance(): this
    {
        if (!this.first) { return this; }
        this.first = this.first.Next;
        return this;
    }

    public Add(item: T): this
    {
        const newItem: IListItem<T> = new ListItem(item);
        this.add(newItem, this.last, null);
        return this;
    }

    public Insert(index: number, item: T): this
    {
        const newItem: IListItem<T> = new ListItem(item);

        if (index === 0)
        {
            newItem.Next = this.first;
            this.first = newItem;
            return;
        }

        let last: IListItem<T>;
        let currentItem: IListItem<T> = this.first;
        while (index && currentItem.Next)
        {
            last = currentItem;
            currentItem = currentItem.Next;
            index--;
        }

        return this.add(currentItem, last, currentItem.Next);
    }

    public Remove(item: T): this
    {
        let last: IListItem<T>;
        let current: IListItem<T> = this.first;
        while (current)
        {
            if (current.Value === item)
            {
                this.remove(current, last, current.Next);
                return this;
            }
            last = current;
            current = current.Next;
        }
        return this;
    }

    public RemoveAt(index: number): this
    {
        let last: IListItem<T>;
        let current: IListItem<T> = this.first;
        while (index--)
        {
            current = current.Next;
            if (!current) { return this; }
        }

        this.remove(current, last, current.Next);
        return this;
    }

    protected add(item: IListItem<T>, before: IListItem<T>, after: IListItem<T>): this
    {
        item.Next = after;
        if (!before)
        {
            this.first = item;
            return this;
        }

        if (!after)
        {
            this.last = item;
        }

        before.Next = item;
        return this;
    }

    protected remove(item: IListItem<T>, before: IListItem<T>, after: IListItem<T>): this
    {
        if (!before)
        {
            this.first = item.Next;
            return this;
        }

        if (!after)
        {
            this.last = before;
        }

        before.Next = after;
        return this;
    }
}
