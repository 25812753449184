/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import ILoggable from "./ILoggable";

export default class Logger
{
    private static _loggerMap: Map<symbol | string, LoggerInstance> = new Map();

    public static Get(loggable: ILoggable): LoggerInstance
    {
        let instance: LoggerInstance = Logger._loggerMap.get(loggable.LogKey);
        if (!instance)
        {
            instance = new LoggerInstance();
            Logger._loggerMap.set(loggable.LogKey, instance);
        }
        return instance;
    }
}

// tslint:disable:no-console
export class LoggerInstance
{
    public Success(message: string, color: LogColor = null): void
    {
        console.log(`%c${message}`, this.getLogStyle(color || LogColor.Green));
    }

    public Log(message: string, color: LogColor = null): void
    {
        console.log(`%c${message}`, this.getLogStyle(color || LogColor.Black));
    }

    public Warn(message: string): void
    {
        console.warn(message);
    }

    public Error(message: string): void
    {
        console.error(message);
    }

    private getLogStyle(color: LogColor): string
    {
        return `color: ${color};`;
    }
}
// tslint:enable:no-console

export enum LogColor
{
    White = '#FFFFFF',
    Black = '#000000',
    Green = '#05C32D',
    Red = '#cc0000',
    GreenBlue = '#05C38C',
    LightBlue = '#059BC3',
    Blue = '#053CC3',
    Purple = '#8C05C3',
    Mustard = '#C3BF05',
    SlateGray = '#666699',
    Turquoise = '#09A19A',
    LightOrange = '#C38C05'
}
