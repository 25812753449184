/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
export enum PageNames
{
    LOGIN = 'login',
    GAME_LIST = 'gamelist',
    TEST_LIST = 'testlist',
    FAKE_GAME = 'fakegame',
    BROWSER_INFO = 'browserinfo',
    REPORT = 'report',
    TEST_RESULTS = 'testresults'
}
