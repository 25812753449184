/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */

export enum ClientEvents
{
    GAME_LOADED = 'GAME_LOADED',
    WAGER = 'WAGER',
    WAGER_REQUESTED = 'WAGER_REQUESTED',
    RESULT = 'RESULT',
    INSUFFICIENT_FUND = 'INSUFFICIENT_FUND',
    USER_INPUT_PROMPT = 'USER_INPUT_PROMPT',
    ADVANCE_PLAY = 'ADVANCE_PLAY',
    ERROR = 'ERROR',
    BONUS_START = 'BONUS_START',
    BONUS_SPIN = 'BONUS_SPIN',
    BONUS_END = 'BONUS_END',
    BONUS_PICK_PROMPT = 'BONUS_PICK_PROMPT',
    BONUS_PICK_SELECT = 'BONUS_PICK_SELECT',
    BONUS_PICK_AWARD = 'BONUS_PICK_AWARD'
}
