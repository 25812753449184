/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import Constants from '../../../common/Constants';
import { LogColor } from '../Utility/Log/Logger';
import IGameInfoVM from '../ViewModel/IGameInfoVM';
import TestInstanceBase from './TestInstanceBase';
import TestManagerBase from './TestManagerBase';
import TestPlayInstance from './TestPlayInstance';

export default class TestPlayManager extends TestManagerBase
{
    public BeginTesting(): void
    {
        if (this.userOptionVM.GameCount < 1)
        {
            this.Error('No games available, please update filter.');
            return;
        }

        this.hideStart();

        this.testOptionVM.ToggleLogFilter(true);
        this.Log('Starting tests..');

        this.userOptionVM.DisableFilter();

        const complete: Function = (arg: any = null) =>
        {
            this.configureGameInfos(arg);
            this.triggerFirstTest();
        };

        if (this.userOptionVM.UserId.indexOf(Constants.DEFAULT_TEMPLATE) > -1)
        {
            this.testingService
                    .GetUsers(this.userOptionVM.UserId, this.getTotalUsers())
                    .then(usernames => complete(usernames));
        }
        else { complete(); }
    }

    protected getTestInstance(gameInfo: IGameInfoVM, username: string): TestInstanceBase
    {
        return new TestPlayInstance(this, gameInfo,
            {
                Color: this.colorPalette.Next as LogColor,
                TestCount: gameInfo.TestCount,
                UserId: username,
                DtMod: gameInfo.DtMod
            });
    }
}
