/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */

import { withValue } from "./Misc";

export default class StringBuilder implements IToString
{
    private _parts: IToString[] = [];

    constructor(init: IToString = null)
    {
        if (init == null) { return; }
        this._parts.push(init.toString());
    }

    public append(value: IToString): this
    {
        if (value == null) { return this; }
        this._parts.push(value.toString());
        return this;
    }

    public insert(value: IToString, index: number = 0): this
    {
        if (value == null) { return this; }
        this._parts.splice(index, 0, value);
        return this;
    }

    public replace(value: IToString, index: number): this
    {
        if (this._parts[index] == null) { return this; }
        this._parts[index] = value;
        return this;
    }

    public get(index: number): string
    {
        return withValue(this._parts[index], p => p.toString(), '');
    }

    public get length(): number
    {
        return this._parts.length;
    }

    public toString(separator: IToString = ''): string
    {
        return this._parts.join(separator.toString());
    }

    public clear(): this
    {
        this._parts.length = 0;
        return this;
    }
}

interface IToString
{
    toString(): string;
}
