/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import { withValue } from '../../../../common/Misc';
import FileHelperBase from './FileHelperBase';

export default class UploadHelper extends FileHelperBase
{
    private _lastFnRemove: Function;

    public Prompt(): Promise<string>
    {
        return new Promise<string>((resolve, reject) =>
        {
            withValue(this._lastFnRemove, fnRemove => fnRemove());
            return withValue(document.createElement('input'), input =>
            {
                input.style.display = 'none';
                input.type = 'file';
                input.accept = `.${this.extension}`;

                withValue(document.body, body =>
                {
                    input.onchange = e =>
                    {
                        if (!input.files || input.files.length < 1)
                        {
                            fnRejectError('No file selected.');
                            return;
                        }

                        withValue(new FileReader(), reader =>
                        {
                            reader.onload = () =>
                            {
                                fnRemove();
                                resolve(reader.result as string);
                            };

                            reader.onerror = () => fnRejectError('File reader error.');
                            reader.readAsText(input.files[0]);
                        }, () => fnRejectError('Could not create file reader.'));
                    };

                    body.appendChild(input);
                    const fnRemove: () => void = this._lastFnRemove =  () =>
                    {
                        if (!input) { return; }
                        try { body.removeChild(input); }
                        catch (err) { /* swallow */ }
                        this._lastFnRemove = null;
                    };

                    const fnRejectError: (err: string) => void = (err: string): void =>
                    {
                        fnRemove();
                        reject(new Error(err));
                    };

                    input.click();
                }, reject);
            }, reject);
        });
    }
}
