/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import * as $ from 'jquery';

import InputObservableBase, { IInputObservableOptions } from './InputObservableBase';

export default class RadioGroupObservable<T extends string | number> extends InputObservableBase<T, HTMLElement>
{
    private _valueMap: Map<T, JQuery<HTMLElement>> = null;
    private _$radioBoxes: JQuery<HTMLElement> = null;

    private _groupName: string = null;
    private _allowDeselect: boolean = false;

    constructor(elementTag: string, options: IInputObservableOptions<T> & IRadioGroupOptions)
    {
        super(elementTag, options);

        this._groupName = options.GroupName;
        if (options.AllowDeselect === true) { this._allowDeselect = true; };
    }

    protected configureListeners(): void
    {
        if (!this.$rootElement) { return; }
        this.$rootElement.click(this.inputActivityEventHandler);
    }

    protected removeListeners(): void
    {
        if (!this.$rootElement) { return; }
        this.$rootElement.off('click', this.inputActivityEventHandler);
    }

    public SetElement(elementTag: string): InputObservableBase<T, HTMLElement>
    {
        super.SetElement(elementTag);

        if (!this._groupName)
        {
            this.logger.Error('No group name provided.');
            return;
        }

        this.setupValueMap();
        return this;
    }

    public DeSelect(): RadioGroupObservable<T>
    {
        if (!this._allowDeselect) { return; }
        this._$radioBoxes.prop('checked', false);
        return this;
    }

    protected extractValue($element: JQuery<HTMLElement>): T
    {
        return this.$rootElement.find(':checked').val() as T;
    }

    protected inputActivityEventHandler(event: JQuery.Event = null): void
    {
        if (!this.isEnabled) { return; }
        const $target: JQuery<HTMLElement> = this.getEventTarget(event);

        if (this.Value === $target.val())
        {
            $target.prop('checked', false);
            this.setValue(null);
            return;
        }

        super.inputActivityEventHandler(event);
    }

    protected setElementValue(): void
    {
        if (!this._valueMap) { this.setupValueMap(); }

        const $selected: JQuery<HTMLElement> = this._valueMap.get(this.Value);
        if (!$selected)
        {
            this.setValue(null);
            this.logger.Warn(`Value [${this.Value}] does not exist in map.`);
            return;
        }

        $selected.prop('checked', true);
    }

    protected setEnabled(to: boolean): void
    {
        super.setEnabled(to);
        if (!this.$rootElement) { return; }
        this.$rootElement.find('input').prop('disabled', true);
    }

    private setupValueMap(): void
    {
        this._valueMap = new Map();
        this._$radioBoxes = this.$rootElement.find(`input[name=${this._groupName}]:radio`);

        this._$radioBoxes.each((index, element) =>
        {
            const $element: JQuery<HTMLElement> = $(element);
            this._valueMap.set($element.val() as T, $element);
        });
    }
}

interface IRadioGroupOptions
{
    GroupName: string;
    AllowDeselect?: boolean;
}
