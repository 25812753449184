/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import * as $ from 'jquery';

import IReportVM from '../ViewModel/IReportVM';
import ServiceBase from './ServiceBase';

export default class ReportingService extends ServiceBase
{
    constructor(hostUrl: string)
    {
        super('ReportingService', hostUrl);
    }

    public RequestReport(reportVM: IReportVM): Promise<any>
    {
        return new Promise((resolve, reject) =>
        {
            let hostUrl: string = this.hostUrl;

            if (reportVM.ReportType === 'USER')
            {
                // tslint:disable-next-line:max-line-length
                hostUrl = `${hostUrl}/gateway/report/genUserReport/${reportVM.TimeZone}/${reportVM.Operator}/${reportVM.GameName}/${reportVM.UserId}/${reportVM.StartTime}/${reportVM.EndTime}/${reportVM.Email}`;
            }
            else
            {
                // tslint:disable-next-line:max-line-length
                hostUrl = `${hostUrl}/gateway/report/genReport/${reportVM.TimeZone}/${reportVM.Operator}/${reportVM.StartTime}/${reportVM.EndTime}/${reportVM.Email}`;
            }

            $.post(hostUrl);
            // Report service will timeout, always resolving.
            resolve(true);
        });
    }
}
