/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import ProgressiveService from '../Server/ProgressiveService';
import IUserOptionVM from './IUserOptionVM';
import ViewModelBase from './ViewModelBase';
import { getRandomInt } from '../../../common/Misc';
import Decorators from '../Utility/Decorators';

export default class ProgressiveMeterVM extends ViewModelBase
{
    private _gameName: string;
    private _$valueSpan: JQuery<HTMLSpanElement>;

    private _userOptionVM: IUserOptionVM;
    private _progressiveService: ProgressiveService;

    private _currentValueMc: number = 0;

    private _meterClass: string;
    private _linkDownClass: string = 'link-down';
    private _isLinkDown: boolean = false;
    private _mockMeterIncrements: boolean = false;

    private readonly _currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-US',
    {
        style: 'currency',
        currency: 'USD',
    });

    constructor(context: HTMLElement, userOptions: IUserOptionVM, progressiveService: ProgressiveService, meterClass: string)
    {
        super(context, 'ProgressiveMeterVM');
        this._userOptionVM = userOptions;
        this._progressiveService = progressiveService;
        this._meterClass = meterClass;
        this.configureContext();
    }

    protected onContext($context: JQuery<HTMLElement>): void
    {
        this._gameName = $context.data('gamename');
        this._$valueSpan = $context.find('span');

        this.resetMeterValues();

        setTimeout(() =>
        {
            this.startMeterUpdate();
        }, 3000);
    }

    private startMeterUpdate(): void
    {
        (this._mockMeterIncrements ? this.increaseValue : this.updateMeterValues)();
        this._userOptionVM.OnSiteChange(this.resetMeters);
    }

    private _scheduleCount: number = 0;
    @Decorators.BindThis()s
    private resetMeters(): void
    {
        const count: number = ++this._scheduleCount;

        setTimeout(() =>
        {
            if (count !== this._scheduleCount) { return; }

            this.resetMeterValues();
            this._scheduleCount = 0;
        }, 1000);
    }

    public SetValue(valueMc: number): void
    {
        if (!this._$valueSpan) { return; }
        this._currentValueMc = valueMc;
        this._$valueSpan.text(this._currencyFormatter.format(this.convertMcToCash(valueMc)));
    }

    private resetMeterValues(): void
    {
        this._progressiveService
                .GetMeterConfig(
                    this._userOptionVM.PlatformId,
                    this._userOptionVM.OperatorId,
                    this._gameName
                )
                .then(meterInfo =>
                {
                    this.resetAnimation(false);
                    this.SetValue(meterInfo.balance);
                })
                .catch(this.enterLinkDown);
    }

    @Decorators.BindThis()
    private updateMeterValues(): void
    {
        this._progressiveService
                .GetMeterConfig(
                    this._userOptionVM.PlatformId,
                    this._userOptionVM.OperatorId,
                    this._gameName
                )
                .then(meterInfo =>
                {
                    this.SetValue(meterInfo.balance);
                    setTimeout(() => this.updateMeterValues(), 4000);
                })
    }

    @Decorators.BindThis()
    private increaseValue(): void
    {
        if (!this._isLinkDown)
        {
            this.SetValue(this._currentValueMc + 1000);
        }

        setTimeout(() => this.increaseValue(), getRandomInt(4001, 1000));
    }

    @Decorators.BindThis()
    private enterLinkDown(): void
    {
        this.resetAnimation(true);
        this.SetValue(0);
    }

    private convertMcToCash(valueMc: number): number
    {
        return valueMc / 100000;
    }

    private resetAnimation(isLinkDown: boolean): void
    {
        this.setLinkDown(isLinkDown);

        this.$context.removeClass(this._meterClass);
        this.$context[0].offsetWidth;
        this.$context.addClass(this._meterClass);
    }

    private setLinkDown(to: boolean): void
    {
        if (to)
        {
            this.$context.addClass(this._linkDownClass);
        }
        else
        {
            this.$context.removeClass(this._linkDownClass);
        }

        this._isLinkDown = to;
    }
}
