// Excerpted from lodash (https://lodash.com/)

import TypeStrings from "./TypeStrings";

/* MIT LICENSE
====

Permission is hereby granted, free of charge, to any person obtaining
a copy of this software and associated documentation files (the
"Software"), to deal in the Software without restriction, including
without limitation the rights to use, copy, modify, merge, publish,
distribute, sublicense, and/or sell copies of the Software, and to
permit persons to whom the Software is furnished to do so, subject to
the following conditions:

The above copyright notice and this permission notice shall be
included in all copies or substantial portions of the Software.

THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND,
EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE
LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION
OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION
WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.

====
*/

/** Used to match property names within property paths. */
// tslint:disable-next-line:max-line-length
const rePropName: RegExp = /[^.[\]]+|\[(?:(-?\d+(?:\.\d+)?)|(["'])((?:(?!\2)[^\\]|\\.)*?)\2)\]|(?=(?:\.|\[\])(?:\.|\[\]|$))/g;

/** Used to match backslashes in property paths. */
const reEscapeChar: RegExp = /\\(\\)?/g;

export function stringToPath(value: string): string[]
{
    const results: string[] = [];
    if (value.charCodeAt(0) === 46 /* . */)
    {
        results.push('');
    }
    value.replace(rePropName, (match: string, numberValue: number, quote: string, subString: string): string =>
    {
        const result: string = quote
            ? subString.replace(reEscapeChar, '$1')
            : (numberValue.toString() || match);

        results.push(result);
        return result;
    });
    return results;
}

// Gets a deep property using a dot syntax object path, for example "settings.uisettings.color"
export function getProperty(target: object, path: string): string
{
    const paths: string[] = stringToPath(path);
    const length: number = path.length;

    let index: number = 0;
    while (target != null && index < length)
    {
        target = target[paths[index++]];
    }
    return (index && index === length) ? target.toString() : undefined;
}

/**
 * Returns random integer.
 * @param max Max value (exclusive).
 * @param min Min value (inclusive).
 */
export function getRandomInt(max: number, min: number = 0): number
{
    return Math.floor(Math.random() * (max - min)) + min;
}

/**
 * Returns random integer with exact number of digits.
 * @param digits Exact digits random must have.
 */
export function getRandomIntExact(digits: number = 1): number
{
    const max: number = Math.pow(10, digits);
    const min: number = max / 10;

    return getRandomInt(max, min);
}

/**
 * Returns random string.
 * @param length of string to return.
 */
export function getRandomString(length: number): string
{
    const rando: string = Math.random().toString(36).substr(2, length);
    length -= rando.length;

    return length > 0
        ? rando + getRandomString(length)
        : rando;
}

export function getTimestamp(): string
{
    return (Date.now() % 1000000).toString();
}

export function extractErrorMessage(err: any): string
{
    if (err == null) { return null; }
    if (typeof(err) === TypeStrings.String) { return err; }
    if (err.message) { return err.message; }
    if (err.toString) { return err.toString(); }
    return err;
}

export function within(min: number, max: number, value: number): number
{
    return Math.min(max, Math.max(min, value));
}

export function  withValue<T, R>(
    object: T,
    fnWith: (v: T) => R,
    fnElse: (() => R) | R = null): R
{
    return object != null
        ? fnWith(object)
        : typeof fnElse === 'function'
            ? (fnElse as () => R)()
            : fnElse;
}

export function toFixedWhole(value: number, minDigits: number): string
{
    const stringValue: string = value.toString();
    const missingDigits: number = minDigits - stringValue.length;
    if (missingDigits < 1) { return stringValue; }

    return (new Array(missingDigits)).fill(0, 0, missingDigits).join('') + stringValue;
}

export function pStringify(object: Object): string
{
    try
    {
        return JSON.stringify(object, null, 4);
    }
    catch (e)
    {
        return '';
    }
}

export function isNullOrEmpty(value: IContainLength): boolean
{
    return value == null || value.length < 1;
}

export function characterString(char: string, length: number): string
{
    if (length < 1) { return ''; }
    return new Array(length).fill(char).join('');
}

interface IContainLength
{
    length: number;
}
