/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import * as $ from 'jquery';

import TypeStrings from '../../../common/TypeStrings';
import LoggableBase from '../Utility/Log/LoggableBase';

export default class ServiceBase extends LoggableBase
{
    public static readonly STATUS_OK: string = 'OK';

    protected hostUrl: string = '';

    constructor(className: string, hostUrl: string)
    {
        super(className);

        this.hostUrl = hostUrl;
    }

    protected post(endPoint: string, resolve: Function, reject: Function, params: Object = null): void
    {
        $.post(`${this.hostUrl}${endPoint}`, params,
                data => this.resultHandler(data, resolve, reject))
            .fail((req, status, err) => this.failHandler(status, err, reject));
    }

    protected resultHandler(result: any, resolve: Function, reject: Function): void
    {
        try
        {
            if (result === ServiceBase.STATUS_OK) { return resolve(); }
            if (typeof(result) === TypeStrings.String) { result = JSON.parse(result); }
        }
        catch (err)
        {
            resolve(result);
        }
        if (result && result.error) { return reject(new Error(result.error)); }
        resolve(result);
    }

    protected failHandler(status: string | number, err: Error | string, reject: Function): void
    {
        if (status === 'error') { status = ''; }
        reject(status ? `${err} - ${status}` : err);
    }
}
