/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import * as $ from 'jquery';

import Constants from '../../../common/Constants';
import { extractErrorMessage, isNullOrEmpty, pStringify, withValue } from '../../../common/Misc';
import { DomMouseEvent, draggable, isChecked, ToggleClass } from '../JUtility/JUtil';
import AccountingService from '../Server/AccountingService';
import IServerSettings from '../Server/IServerSettings';
import SettingsService from '../Server/SettingsService';
import TestingService from '../Server/TestingService';
import { ClientEvents } from '../TestPlay/ClientEvents';
import TestPlayInstance from '../TestPlay/TestPlayInstance';
import Decorators from '../Utility/Decorators';
import DownloadHelper from '../Utility/File/DownloadHelper';
import UploadHelper from '../Utility/File/UploadHelper';
import { MimeType } from '../Utility/MimeType';
import CheckBoxObservable from '../Utility/Observable/CheckBoxObservable';
import ContextElement, { HTMLContextElement } from '../Utility/Observable/ContextElement';
import InputNumberObservable from '../Utility/Observable/InputNumberObservable';
import InputObservable from '../Utility/Observable/InputObservable';
import RadioGroupObservable from '../Utility/Observable/RadioGroupObservable';
import SelectObservable from '../Utility/Observable/SelectObservable';
import UrlHelper from '../Utility/UrlHelper';
import IGameInfoVM from './IGameInfoVM';
import IGameUpdateCriteria from './IGameUpdateCriteria';
import ISettingsSave from './ISettingsSave';
import IUserOptionVM from './IUserOptionVM';
import UserOptionVM from './UserOptionVM';

export default class UserTestOptionVM extends UserOptionVM implements IUserOptionVM
{
    public static readonly SETTINGS_KEY: string = 'everi.storage.settings';
    public static readonly MILLICENT_MULTIPLE: number = 100000;

    private _userIdObservable: InputObservable
        = new InputObservable('#user-name',
            {
                LocalStorageKey: 'everi.storage.username',
                UrlParameterKey: 'USER'
            });
    public get UserId(): string { return this._userIdObservable.Value; }
    public set UserId(value: string) { this._userIdObservable.Value = value; }

    private _platformIdObservable: InputObservable
        = new InputObservable('#platform',
            {
                LocalStorageKey: 'everi.storage.platform',
                DefaultValue: 'platform_everi',
                UrlParameterKey: 'PLATFORM',
                OnFocusSelect: true
            });
    public get PlatformId(): string { return this._platformIdObservable.Value; }

    private _operatorIdObservable: InputObservable
        = new InputObservable('#operator',
            {
                LocalStorageKey: 'everi.storage.operator',
                DefaultValue: 'operator_everi',
                UrlParameterKey: 'OPERATOR',
                OnFocusSelect: true
            });
    public get OperatorId(): string { return this. _operatorIdObservable.Value; }

    private _jurisdictionIdObservable: InputObservable
        = new InputObservable('#jurisdiction',
            {
                LocalStorageKey: 'everi.storage.jurisdiction',
                DefaultValue: 'everi',
                UrlParameterKey: 'JURISDICTION',
                OnFocusSelect: true
            });
    public get JurisdictionId(): string { return this._jurisdictionIdObservable.Value; }

    private _optionalParamsObservable: InputObservable
        = new InputObservable('#optional-params',
            {
                LocalStorageKey: 'everi.storage.optionalparams',
                UrlParameterKey: 'OPTIONAL',
                OnFocusSelect: true
            });
    public get OptionalParams(): string { return this._optionalParamsObservable.Value; }

    protected eventContainerContext: HTMLContextElement = new HTMLContextElement('#game-events-container');
    protected eventSendContext: HTMLContextElement = new HTMLContextElement('.send-only');

    // #region Cached Settings

    private _settings: ISettingsSave[] = [];

    private _selectedSettingsObservable: SelectObservable<string> = new SelectObservable('#settings');
    private _settingsNameObservable: InputObservable = new InputObservable('#settings-name');

    private _saveSettingsButtonContext: HTMLContextElement = new HTMLContextElement('#save-settings');
    private _deleteSettingsButtonContext: HTMLContextElement = new HTMLContextElement('#delete-settings');

    private _saveServerSettingsContext: HTMLContextElement = new HTMLContextElement('#save-settings-server');
    private _fetchServerSettingsContext: HTMLContextElement = new HTMLContextElement('#fetch-settings-server');

    private _gameListButtonContext: HTMLContextElement = new HTMLContextElement('.game-downloader');

    // #endregion

    private _tokenObservable: InputObservable
        = new InputObservable('#token', { LocalStorageKey: 'everi.storage.token', UrlParameterKey: 'TOKEN' });
    public get Token(): string { return this._tokenObservable.Value; }

    private _tokenRefreshButtonContext: HTMLContextElement = new HTMLContextElement('#token-refresh');

    private _launchUrlObservable: InputObservable
        = new InputObservable('#launch-url',
        {
            LocalStorageKey: 'everi.storage.launch-url',
            UrlParameterKey: 'LAUNCH'
        });
    public get LaunchUrl(): string { return this._launchUrlObservable.Value; }

    private _paytableObservable: InputObservable
        = new InputObservable('#paytable',
        {
            LocalStorageKey: 'everi.storage.paytable',
            UrlParameterKey: 'PAYTABLE'
        });
    public get Paytable(): string { return this._paytableObservable.Value; }

    private _betConfigIds: InputObservable
        = new InputObservable('#bet-config-ids',
        {
            LocalStorageKey: 'everi.storage.bets',
            UrlParameterKey: 'BETS'
        });
    public get BetConfigIds(): string { return this._betConfigIds.Value; }

    private _maxPrizeIndexObservable: InputNumberObservable
        = new InputNumberObservable('#max-prize-index',
        {
            LocalStorageKey: 'everi.storage.maxPrizeIndex',
            UrlParameterKey: 'MAX-PRIZE',
            OnFocusSelect: true,
            DefaultValue: 500000
        });
    public get MaxPrizeIndex(): number { return this._maxPrizeIndexObservable.Value; }

    private _minPrizeIndexObservable: InputNumberObservable
        = new InputNumberObservable('#min-prize-index',
        {
            LocalStorageKey: 'everi.storage.minPrizeIndex',
            UrlParameterKey: 'MIN-PRIZE',
            OnFocusSelect: true,
            DefaultValue: 0
        });
    public get MinPrizeIndex(): number { return this._minPrizeIndexObservable.Value; }

    private _includStandardObservable: CheckBoxObservable
        = new CheckBoxObservable('#prize-standard', { DefaultValue: true });
    public get IncludeStandard(): boolean { return this._includStandardObservable.Value; }

    private _includeBonusObservable: CheckBoxObservable
        = new CheckBoxObservable('#prize-bonus', { DefaultValue: true });
    public get IncludeBonus(): boolean { return this._includeBonusObservable.Value; }

    private _includBranchObservable: CheckBoxObservable
        = new CheckBoxObservable('#set-branch', { DefaultValue: true });
    public get IncludeBranch(): boolean { return this._includBranchObservable.Value; }

    private _includeReelStopObservable: CheckBoxObservable
        = new CheckBoxObservable('#set-reel-stop',
        {
            DefaultValue: false,
            UrlParameterKey: 'TEST-REEL-STOP'
        });
    public get IncludeReelStop(): boolean { return this._includeReelStopObservable.Value; }

    private _reelStopSkipObservable: InputNumberObservable
        = new InputNumberObservable('#reel-stop-skip',
        {
            DefaultValue: 0,
            UrlParameterKey: 'TEST-REEL-SKIP',
            OnFocusSelect: true
        });
    public get ReelStopSkip(): number { return this._reelStopSkipObservable.Value; }

    private _testGameIdObservable: InputObservable
        = new InputObservable('#test-game-id',
        {
            LocalStorageKey: 'everi.storage.testgameid',
            UrlParameterKey: 'TEST-GAME'
        });
    public get TestGameId(): string { return this._testGameIdObservable.Value; }

    private _testKeyObservable: InputObservable
        = new InputObservable('#test-key',
        {
            LocalStorageKey: 'everi.storage.testKey',
            DefaultValue: 'Test',
            UrlParameterKey: 'KEY'
        });
    public get TestKey(): string { return this._testKeyObservable.Value; }

    private _launchInIframeObservable: CheckBoxObservable
        = new CheckBoxObservable('#launch-iframe', { UrlParameterKey: 'IFRAME' });
    public get LaunchInIframe(): boolean { return this._launchInIframeObservable.Value; }

    private _isPortraitObservable: CheckBoxObservable
        = new CheckBoxObservable('#is-portrait', { UrlParameterKey: 'PORTRAIT' });
    public get IsPortrait(): boolean { return this._isPortraitObservable.Value; }

    private _showEventsObservable: CheckBoxObservable
        = new CheckBoxObservable('#show-events', { UrlParameterKey: 'EVENTS' });

    private _frameWidthInputObservable: InputNumberObservable = new InputNumberObservable('.frame-width');
    public get FrameWidth(): number { return this._frameWidthInputObservable.Value; }

    private _frameHeightInputObservable: InputNumberObservable = new InputNumberObservable('.frame-height');
    public get FrameHeight(): number { return this._frameHeightInputObservable.Value; }

    private _gameNameObservable: SelectObservable<string> = new SelectObservable('select.game-name');
    public get GameName(): string { return this._gameNameObservable.Value; }

    private _resolutionObservable: RadioGroupObservable<string>
        = new RadioGroupObservable('#res-option',
            {
                GroupName: 'res',
                UrlParameterKey: 'RES',
                AllowDeselect: true
            });
    public get Resolution(): string { return this._resolutionObservable.Value; }

    private _userOptionsContext: HTMLContextElement = new HTMLContextElement('div.user-options');
    private _toggleButtonContext: HTMLContextElement = new HTMLContextElement('button.user-option-toggle');
    private _gameContainerContext: HTMLContextElement = new HTMLContextElement('#game-container');
    public get $GameContainer(): JQuery<HTMLElement> { return this._gameContainerContext.$Element; }

    private _balanceGetButtonContext: HTMLContextElement = new HTMLContextElement('button.balance-get');
    private _balanceGetInputContext: HTMLContextElement = new HTMLContextElement('input.balance-get');

    private _balanceAddButtonContext: HTMLContextElement = new HTMLContextElement('button.balance-add');
    private _balanceSetButtonContext: HTMLContextElement = new HTMLContextElement('button.balance-set');
    private _balanceAddObservable: InputNumberObservable = new InputNumberObservable('input.balance-add');
    public get BalanceAdd(): number { return this._balanceAddObservable.Value; }
    private _balanceTitleContext: HTMLContextElement = new HTMLContextElement('legend.balance');

    private _testUserIdObservable: InputNumberObservable = new InputNumberObservable('input.test-user-id');

    private _prizeOptionsContext: HTMLContextElement = new HTMLContextElement('.user-option.prize');
    private _gameOptionsContext: HTMLContextElement = new HTMLContextElement('.user-option.game');
    private _randoUserButtonContext: HTMLContextElement = new HTMLContextElement('button.rando-user-id');
    private _testUserButtonContext: HTMLContextElement = new HTMLContextElement('button.test-user-id');

    private _uploadBuiltTemplateButtoncontext: HTMLContextElement = new HTMLContextElement('button.build-template');

    // Services
    private readonly _accountingService: AccountingService;
    private readonly _settingsService: SettingsService;
    private readonly _testingService: TestingService;

    // Balance
    private _displayCash: boolean = true;
    private _lastBalance: number = 0;

    // Event
    private _eventDatas: Object[] = [];
    private _eventIndex: number = 0;

    private _resolutions: object =
    {
        LOW: 500,
        MEDIUM: 1500,
        HIGH: 2000
    };

    private _currencyFormatter: Intl.NumberFormat = new Intl.NumberFormat('en-US',
    {
        style: 'currency',
        currency: 'USD',
    });

    private _$testGameContainer: JQuery<HTMLElement> = null;
    private _testCount: number = 5;

    private _downloadHelper: DownloadHelper = new DownloadHelper();
    private _uploadHelper: UploadHelper = new UploadHelper();

    constructor(
        context: HTMLElement = null,
        accountingService: AccountingService,
        settingsService: SettingsService,
        testingService: TestingService)
    {
        super(context, 'UserTestOptionVM');

        this._accountingService = accountingService;
        this._settingsService = settingsService;
        this._testingService = testingService;

        this.configureContext();
    }

    protected onContext($context: JQuery<HTMLElement>): void
    {
        this._$testGameContainer = $('#test-game-container');

        this.configureListeners();
        this.configure($('p.description'));

        this.loadSavedSettings();
        this.parseUrlParameters();
        this.gameInfos.forEach(info => info.TestCount = this._testCount);

        this.setOptionsToggleVisibility(UrlHelper.Instance.Parameter('NOOPT') === null);

        this.validate();
        this.validateEventUi();
        this.ResetGameFrame();
    }

    private parseUrlParameters(): void
    {
        try
        {
            const countValue: string = UrlHelper.Instance.Parameter('COUNT');
            if (!countValue) { throw new Error('No [COUNT] parameter.'); }
            this._testCount = Number.parseInt(countValue, 10) || this._testCount;
        }
        catch (error)
        {
            this.logger.Log('No test count parameter override.');
        }
    }

    private downloadCsv(): void
    {
        const data: string[] = ['GameId,Version'];
        this.ForEachVisible(info => data.push(`${info.GameName},${info.Version}`));

        this._downloadHelper
            .SetMimeType(MimeType.CSV)
            .AddTimestamp()
            .Download('GameList', [data.join('\n')]);
    }

    private downloadJson(): void
    {
        const data: Object = {};
        this.ForEachVisible(info => data[info.GameName] =
        {
            displayName: info.DisplayName,
            startVersion: `v${this.decrementVersion(info.Version)}`,
            endVersion: `v${info.Version}`
        });

        this._downloadHelper
            .SetMimeType(MimeType.JSON)
            .AddTimestamp()
            .Download('Config', [pStringify(data)]);
    }

    private downloadOther(): void
    {
        const data: IGameUpdateCriteria = {};

        this.ForEachVisible(info => this.addGameInfoBuild(info, data));

        this._downloadHelper
            .SetMimeType(MimeType.JSON)
            .AddTimestamp()
            .Download('Config', [pStringify(data)]);
    }

    private addGameInfoBuild(info: IGameInfoVM, data: Object): void
    {
        withValue(info.Version.split('-'), gameLibraryVersions =>
        {
            if (gameLibraryVersions.length < 2) { return; }

            const game: string[] = withValue(gameLibraryVersions[0], s => s.split('.'));
            const library: string[] = withValue(gameLibraryVersions[1], s => s.split('.'));

            let template: string = BUILD_TEMPLATE.slice();
            template = template.split('{game.branch}').join(this.getGameBranch(game));
            template = template.split('{game.major}').join(game[0]);
            template = template.split('{game.minor}').join(game[1]);
            template = template.split('{lib.major}').join(library[0]);
            template = template.split('{lib.minor}').join(library[1]);

            try
            {
                data[info.GameName] = JSON.parse(template);
            }
            catch (e)
            {
                // tslint:disable-next-line: max-line-length
                this.logger.Warn(`Error parsing build template for game: [${info.GameName}].. ${extractErrorMessage(e)}`);
            }
        });
    }

    private getGameBranch(verions: string[]): string
    {
        if (!verions
            || (verions[0] === '0' && verions[1] === '0')) { return 'develop'; }
        return `release/${verions[0]}.${verions[1]}`;
    }

    private decrementVersion(version: string): string
    {
        const parts: string[] = version.split('-');
        const gameVersion: string = parts[0];
        if (!gameVersion) { return version; }

        const majorMinorBuild: string[] = gameVersion.split('.');
        const build: string = majorMinorBuild[2];
        if (!build) { return version; }

        try
        {
            const buildNumber: number = Math.max(parseInt(build, 10) - 1, 0);
            majorMinorBuild[2] = buildNumber.toString();
        }
        catch { return version; }

        parts[0] = majorMinorBuild.join('.');
        return parts.join('-');
    }

    private configureListeners(): void
    {
        $(window).on('resize', () =>
        {
            this.ResetGameFrame();
            this.updateGameInfoScrollWidth();
        });

        this._userIdObservable.OnChanged(this.validate);
        this._testUserIdObservable.OnChanged(this.validate);

        this._tokenRefreshButtonContext.OnClick(this.refreshTokenHandler);

        this._balanceAddObservable.OnChanged(this.validate);

        this._includBranchObservable.OnChanged(val => this.validateTypes(val));

        this._includeReelStopObservable.OnChanged(() => this.validateIncludes());
        this._includBranchObservable.OnChanged(() => this.validateIncludes());

        this._resolutionObservable.OnChanged(this.resolutionChangedHandler);
        this._launchInIframeObservable.OnChanged(this.launchIframeChangedHandler);
        this._isPortraitObservable.OnChanged(this.isPortraitChangedHandler);

        this._selectedSettingsObservable.OnChanged(this.selectedSettingChangedHandler);

        this._toggleButtonContext.OnClick(() => this.ToggleUserOptions());
        this._balanceGetButtonContext.OnClick(this.balanceGetClickHandler);
        this._balanceAddButtonContext.OnClick(this.balanceAddClickHandler);
        this._balanceSetButtonContext.OnClick(this.balanceSetClickHandler);
        this._balanceTitleContext.OnClick(this.toggleCashDisplay);

        this._saveSettingsButtonContext.OnClick(this.saveSettingsEventHandler);
        this._deleteSettingsButtonContext.OnClick(this.deleteSettingsEventHandler);

        this._saveServerSettingsContext.OnClick(this.serverSaveButtonClickHandler);
        this._fetchServerSettingsContext.OnClick(this.serverFetchButtonClickHandler);

        this._uploadBuiltTemplateButtoncontext.OnClick(this.uploadBuildTemplateHandler);

        // Test User
        this._testUserButtonContext.OnClick(
            () => this._testingService
                        .SetUserIndex(this.UserId, this._testUserIdObservable.Value)
                        .then(() => this._testUserIdObservable.Clear())
                        .catch(err => this.logger.Error(err)));
        this._randoUserButtonContext.OnClick(
            () => this._testUserIdObservable.Value = Math.floor(Math.random() * 1001));

        this._gameListButtonContext.$Element.on(DomMouseEvent.CLICK, ($event) =>
        {
            withValue($($event.target).data('mimetype'), type =>
            {
                switch (type)
                {
                    case 'csv':
                        this.downloadCsv();
                        return;
                    case 'json':
                        this.downloadJson();
                        return;
                    case 'other':
                        this.downloadOther();
                        return;
                    default:
                        alert('Uknown mimetype.');
                        return;
                }
            });
        });

        this.configureEventDisplay();

        draggable(this.eventContainerContext.$Element);
    }

    private configureEventDisplay(): void
    {
        this._showEventsObservable.OnChanged(val =>
        {
            const $container: JQuery<HTMLElement> = this.eventContainerContext.$Element;
            val ? $container.show() : $container.hide();
        });

        window.addEventListener('message', ev => this.addEventMessage(this.getEventData(ev.data)));


        const $eventContainer = this.eventContainerContext.$Element;
        if (!$eventContainer) { return; }

        withValue($eventContainer.find('button'), $buttons =>
        {
            const $stringifyCheckbox: JQuery<HTMLInputElement> = $eventContainer.find('input[type="checkbox"]') as any;

            $buttons[0].addEventListener(DomMouseEvent.CLICK, () => this.updateMessageIndex(-1));
            $buttons[1].addEventListener(DomMouseEvent.CLICK, () => this.updateMessageIndex(1));
            $buttons[2].addEventListener(DomMouseEvent.CLICK, () => this.downloadEvents());
            $buttons[3].addEventListener(DomMouseEvent.CLICK, () => this.clearEvents());
            $buttons[4].addEventListener(DomMouseEvent.CLICK, () => this.postMessage($stringifyCheckbox));
            $buttons[5].addEventListener(DomMouseEvent.CLICK, () => this.closeEventSend());
        });
    }

    public OnSiteChange(fnOnSiteChange: () => void): this
    {
        this._operatorIdObservable.OnChanged(fnOnSiteChange, false);
        this._platformIdObservable.OnChanged(fnOnSiteChange, false);
        this._jurisdictionIdObservable.OnChanged(fnOnSiteChange, false);
        return this;
    }

    private addEventMessage(data: Object): void
    {
        this._eventDatas.push(data);
        this._eventIndex = 0;
        this.showEventMessage();
    }

    private getEventData(dataString: string): Object
    {
        let data: Object;

        try
        {
            data = JSON.parse(dataString);
        }
        catch (e)
        {
            data = { error: extractErrorMessage(e) };
        }

        return data;
    }

    @Decorators.BindThis()
    private validate(): void
    {
        let balanceAdd: number = 0;

        try
        {
            balanceAdd = this._balanceAddObservable.Value;
        }
        catch (error)
        {
            this._balanceAddObservable.SetValue(null);
        }

        const balanceAddEmpty: boolean = !balanceAdd;
        const isDisabled: boolean = !this.UserId || !this.GameName;
        const testIdEmpty: boolean = !this._testUserIdObservable.HasValue();

        this._balanceAddButtonContext.$Element.prop('disabled', balanceAddEmpty || isDisabled);
        this._balanceSetButtonContext.$Element.prop('disabled', balanceAddEmpty || isDisabled || balanceAdd < 0);
        this._balanceGetButtonContext.$Element.prop('disabled', isDisabled);
        this._testUserButtonContext.$Element.prop('disabled', testIdEmpty);
    }

    private validateTypes(to: boolean): void
    {
        if (to)
        {
            this._includStandardObservable.SetEnabled(true);
            this._includeBonusObservable.SetEnabled(true);
            return;
        }

        this._includStandardObservable.Value = true;
        this._includeBonusObservable.Value = true;
        this._includStandardObservable.SetEnabled(false);
        this._includeBonusObservable.SetEnabled(false);
    }

    private validateIncludes(): void
    {
        if (this._includBranchObservable.Value)
        {
            this._includeReelStopObservable.SetEnabled(true);
        }
        else
        {
            this._includeReelStopObservable
                    .SetValue(false)
                    .SetEnabled(false);
        }

        if (this._includeReelStopObservable.Value)
        {
            this._reelStopSkipObservable.SetEnabled(true);
        }
        else
        {
            this._reelStopSkipObservable
                    .SetValue(0)
                    .SetEnabled(false);
        }
    }

    // #region UI Helpers

    public SelectGameInfo(gameName: string, onlySelect: boolean = true): this
    {
        const selected: IGameInfoVM = gameName
            ? this.gameInfos.filter(gameInfo => gameInfo.Filter === gameName)[0]
            : null;
        if (!selected)
        {
            this.logger.Warn(`Could not select game with name: [${gameName}]`);
        }

        for (const gameInfo of this.gameInfos)
        {
            if (selected && gameInfo.Filter === gameName)
            {
                if (!gameInfo.IsSelected())
                {
                    gameInfo.Select();
                    continue;
                }
                if (onlySelect) { continue; }
            }
            gameInfo.ClearSelected();
        }

        return this;
    }

    public ShowPrizeOptions(): this
    {
        this._prizeOptionsContext.$Element.show();
        return this;
    }

    public HideGameOptions(): this
    {
        this._gameOptionsContext.$Element.hide();
        return this;
    }

    public ToggleGameContainer(show: boolean = true): this
    {
        if (!this._gameContainerContext) { return this; }

        this._launchInIframeObservable.Value = show;

        if (show)
        {
            return this.ResetGameFrame();
        }

        this._gameContainerContext.$Element.hide();
        return this;
    }

    public SelectLaunchIframe(): this
    {
        this._launchInIframeObservable.Value = true;
        return this;
    }

    public DisableIframeOptions(): this
    {
        this._resolutionObservable.SetEnabled(false);
        this._launchInIframeObservable.SetEnabled(false);
        this._isPortraitObservable.SetEnabled(false);
        return this;
    }

    public AddClassToGameContainer(className: string): this
    {
        this._gameContainerContext.$Element.addClass(className);
        return this;
    }

    @Decorators.BindThis()
    public ToggleUserOptions(override: boolean = null): this
    {
        const userOptions: JQuery<HTMLElement> = this._userOptionsContext.$Element;
        if (!userOptions) { return; }

        if (override === null)
        {
            userOptions.toggle();
            return;
        }

        if (override)
        {
            userOptions.show();
        }
        else
        {
            userOptions.hide();
        }

       
        return this.updateGameInfoScrollWidth();
    }

    private setOptionsToggleVisibility(toVisible: boolean): void
    {
        if (toVisible) { this._toggleButtonContext.$Element.show(); }
        else { this._toggleButtonContext.$Element.hide(); }
    }

    public ResetGameFrame(): this
    {
        let windowWidth: number = $(window).width();
        this.logger.Log(`WINDOW WIDTH = ${windowWidth}`);

        let newContainerSize: number;

        if (this.IsPortrait)
        {
            let windowHeight: number = screen.availHeight;

            if (windowHeight < 800)
            {
                windowHeight *= .95;
            }
            else
            {
                windowHeight *= .65;
            }

            newContainerSize = windowHeight * 9 / 16.0;
            this.logger.Log(`New window width: [${windowWidth}]`);
            return this.SetFrame(newContainerSize, windowHeight);
        }
        else
        {
            if (windowWidth < 1000)
            {
                windowWidth *= .9;
            }
            else
            {
                windowWidth *= .65;
            }

            newContainerSize = windowWidth * 9 / 16.0;

            this.logger.Log(`New window width: [${windowWidth}]`);
            return this.SetFrame(windowWidth, newContainerSize);
        }
    }

    public SetFrame(width: number, height: number): this
    {
        const $gameFrame: JQuery<HTMLElement> = this.gameIFrameContext.$Element;

        this._gameContainerContext.$Element.width(width);
        $gameFrame.width(width);

        this._gameContainerContext.$Element.height(height);
        $gameFrame.height(height);

        this._frameWidthInputObservable.SetValue(Math.floor(width));
        this._frameHeightInputObservable.SetValue(Math.floor(height));
        return this;
    }

    public OnGameLoadedOnce(fnOnLoaded: () => void): this
    {
        const eventHandler: () => void = () =>
        {
            fnOnLoaded();
            window.removeEventListener('message', eventHandler);
        };

        window.addEventListener('message', eventHandler);
        return this;
    }

    // #endregion

    // #region Input Helpers

    @Decorators.BindThis()
    private toggleCashDisplay(): void
    {
        this._displayCash = !this._displayCash;
        this.setCurrencyInputValue(this._balanceGetInputContext.$Element, this._lastBalance);
    }

    private setCurrencyInputValue($balanceInput: JQuery<HTMLElement>, value: number): void
    {
        this.setInputMessage(
            $balanceInput,
            this.getBalanceDisplay(value),
            'black'
        );
    }

    private getBalanceDisplay(balanceInMC: number): string
    {
        if (!this._displayCash) { return balanceInMC.toString(); }

        const balanceInCents: number = Math.floor(balanceInMC / UserTestOptionVM.MILLICENT_MULTIPLE);
        return this._currencyFormatter.format(balanceInCents);
    }

    private setInputMessage(
        $input: JQuery<HTMLElement>,
        message: string,
        color: string): void
    {
        $input
            .addClass('short')
            .prop('type', 'text')
            .css('color', color)
            .val(message);
    }

    // #endregion

    // #region Event Handlers

    @Decorators.BindThis()
    private refreshTokenHandler(): boolean
    {
        this._accountingService
                .GetToken(this._userIdObservable.Value, this._gameNameObservable.Value)
                .then(token => this._tokenObservable.Value = token);
        return false;
    }

    @Decorators.BindThis()
    private serverSaveButtonClickHandler(): boolean
    {
        let name: string = prompt('Enter name:');
        if (name && name.trim) { name = name.trim(); }
        if (!name) { return false; }

        this._settingsService
                .SaveSettings(name, JSON.stringify(this._settings))
                .then((result: any) =>
                {
                    if (result === true) { alert(`Successfully saved [${name}] to server!`); }
                })
                .catch((message: any) => alert(message));

        return false;
    }

    @Decorators.BindThis()
    private serverFetchButtonClickHandler(): boolean
    {
        let name: string = prompt('Enter name:');
        if (name && name.trim) { name = name.trim(); }
        if (!name) { return false; }

        this._settingsService
                .GetSettings(name)
                .then((result: IServerSettings) =>
                {
                    localStorage.setItem(UserTestOptionVM.SETTINGS_KEY, result.Value);
                    this.loadSavedSettings();
                })
                .catch((message: any) => alert(message));

        return false;
    }

    @Decorators.BindThis()
    private launchIframeChangedHandler(isChecked: boolean): void
    {
        if (isChecked === true)
        {
            this._gameContainerContext.$Element.show();
        }
        else if (isChecked === false)
        {
            this._gameContainerContext.$Element.hide();
            this._resolutionObservable.DeSelect();
            this._isPortraitObservable.Value = false;
        }
    }

    @Decorators.BindThis()
    private isPortraitChangedHandler(isChecked: boolean): void
    {
        if (isChecked)
        {
            if (!this._resolutionObservable.HasValue()) { this._resolutionObservable.Value = 'LOW'; }
        }

        this.ResetGameFrame();
    }

    @Decorators.BindThis()
    private resolutionChangedHandler(value: string): void
    {
        if (this._resolutionObservable.Value)
        {
            this._launchInIframeObservable.Value = true;
            this.UpdateResolution();
            return;
        }

        this._isPortraitObservable.Value = false;
        this.UpdateResolution();
    }

    @Decorators.BindThis()
    private balanceGetClickHandler(): boolean
    {
        this.getBalance();
        return false;
    }

    @Decorators.BindThis()
    private uploadBuildTemplateHandler(): boolean
    {
        this._uploadHelper.Prompt()
            .then(contents =>
            {
                BUILD_TEMPLATE = contents;
                alert(BUILD_TEMPLATE);
            });
        return false;
    }

    private getBalance(): Promise<number>
    {
        return new Promise<any>((resolve, reject) =>
        {
            const $balanceInput: JQuery<HTMLElement> = this._balanceGetInputContext.$Element;

            this.setInputMessage($balanceInput, 'Logging in...', 'green');

            this._accountingService
                    .GetBalance(this.UserId, this.GameName)
                    .then(data =>
                        {
                            if (!data || isNaN(data.balanceInMC))
                            {
                                const message: string = 'Uknown error.';
                                this.setInputMessage($balanceInput, message, 'red');
                                reject(message);
                                return;
                            }

                            this.setCurrencyInputValue($balanceInput, this._lastBalance = data.balanceInMC);
                            resolve(data.balanceInMC);
                        },
                        (error) =>
                        {
                            this.setInputMessage($balanceInput, error.message, 'red');
                            reject(error.message);
                        });
        });
    }

    @Decorators.BindThis()
    private balanceAddClickHandler(): boolean
    {
        const $balanceInput: JQuery<HTMLElement> = this._balanceGetInputContext.$Element;

        let balanceVal: number = this.BalanceAdd;
        if (this._displayCash) { balanceVal = Math.round(balanceVal * UserTestOptionVM.MILLICENT_MULTIPLE); }

        this.setInputMessage($balanceInput, 'Logging in...', 'green');

        this._accountingService
                .AddBalance(balanceVal, this.UserId, this.GameName)
                .then(data =>
                {
                    this.setCurrencyInputValue($balanceInput, this._lastBalance = data.balanceInMC);
                    this._balanceAddObservable.Clear();
                },
                (error) =>
                {
                    this.setInputMessage(
                        $balanceInput,
                        error.message,
                        'red'
                    );
                });

        return false;
    }

    @Decorators.BindThis()
    private balanceSetClickHandler(): boolean
    {
        this.getBalance()
            .then(balanceInMC =>
            {
                const wasDisplayCash: boolean = this._displayCash;

                let balanceAdd: number = this._balanceAddObservable.Value;

                if (this._displayCash)
                {
                    balanceAdd = Math.round(balanceAdd * UserTestOptionVM.MILLICENT_MULTIPLE);
                    this._displayCash = false;
                }

                const diff: number = balanceAdd - balanceInMC;

                this._balanceAddObservable.Value = diff;
                this.balanceAddClickHandler();

                this._displayCash = wasDisplayCash;
            });
        return false;
    }

    // #endregion

    // #region Settings Helpers

    private loadSavedSettings(): void
    {
        this._selectedSettingsObservable.ClearOptions();
        this._settingsNameObservable.Value = '';

        const settingsString: string = localStorage.getItem(UserTestOptionVM.SETTINGS_KEY);
        if (!settingsString) { return; }

        this._settings = JSON.parse(settingsString);
        if (!this._settings || !this._settings.length) { return; }

        this._selectedSettingsObservable.AddOption('');

        for (const setting of this._settings)
        {
            this._selectedSettingsObservable.AddOption(setting.SettingName);
        }
    }

    @Decorators.BindThis()
    private selectedSettingChangedHandler(): boolean
    {
        const selected: string = this._selectedSettingsObservable.Value;
        this._settingsNameObservable.Value = selected;

        const selectedSave: ISettingsSave = this._settings.find(setting => setting.SettingName === selected);
        if (!selectedSave)
        {
            this._selectedSettingsObservable.SelectFirst();
        }
        else
        {
            this._operatorIdObservable.Value = selectedSave.OperatorId;
            this._userIdObservable.Value = selectedSave.UserId;
            this._platformIdObservable.Value = selectedSave.PlatformId;
            this._jurisdictionIdObservable.Value = selectedSave.JurisdictionId;
            this._tokenObservable.Value = selectedSave.Token;
            this._optionalParamsObservable.Value = selectedSave.OptionalParams;
        }

        return false;
    }

    @Decorators.BindThis()
    private saveSettingsEventHandler(): boolean
    {
        const settingsName: string = this._settingsNameObservable.HasValue()
            ? this._settingsNameObservable.Value.trim()
            : this._settingsNameObservable.Value = this.JurisdictionId || this.PlatformId || this.OperatorId;
        if (!settingsName) { return false; }

        const newSetting: ISettingsSave =
        {
            SettingName: settingsName,
            UserId: this.UserId,
            OperatorId: this.OperatorId,
            PlatformId: this.PlatformId,
            JurisdictionId: this.JurisdictionId,
            Token: this.Token,
            OptionalParams: this.OptionalParams
        };

        const existing: number = this._settings.findIndex(setting => setting.SettingName === settingsName);
        if (existing > -1)
        {
            this._settings[existing] = newSetting;
        }
        else
        {
            this._settings.push(newSetting);
        }

        this.updateSettingsCache();
        this._settingsNameObservable.Value = settingsName;
        this._selectedSettingsObservable.Value = settingsName;
        return false;
    }

    @Decorators.BindThis()
    private deleteSettingsEventHandler(): boolean
    {
        const selected: string = this._settingsNameObservable.Value;

        this._settings = this._settings.filter(setting => setting.SettingName !== selected);
        this.updateSettingsCache();
        return false;
    }

    private updateSettingsCache(): void
    {
        localStorage.setItem(UserTestOptionVM.SETTINGS_KEY, JSON.stringify(this._settings));
        this.loadSavedSettings();
    }

    // #endregion

    // #region Test Helpers

    public SetupForTest(
        concurrentTests: number,
        testFinishedHandler: (target: JQuery<HTMLIFrameElement>) => void): this
    {
        const $gameIFrameParent: JQuery<HTMLElement> = this.gameIFrameContext.$Element.parent();

        let cloneCount: number = concurrentTests;
        let $newGameFrame: JQuery<HTMLDivElement> = null;

        while (cloneCount-- > 0)
        {
            withValue(document.createElement('div'), gameFrameDiv =>
            {
                gameFrameDiv.className = 'game-frame';

                gameFrameDiv.appendChild(withValue(document.createElement('p'), pElement =>
                {
                    pElement.className = 'floating-header';
                    pElement.innerText = ' + Click to resize';
                    return pElement;
                }));

                gameFrameDiv.appendChild(withValue(document.createElement('iframe'), iframeElement =>
                {
                    iframeElement.name = `_clonedGameFrame${cloneCount}`;
                    return iframeElement;
                }));

                $newGameFrame = $(gameFrameDiv);
            });

            $newGameFrame
                    .on(ClientEvents.GAME_LOADED, this.SortGamesByCompletion.bind(this))
                    .on(TestPlayInstance.TestFinishedEventName,
                        (event: Event) =>
                        {
                            const $gameIFrame: JQuery<HTMLIFrameElement> = $(event.target) as JQuery<HTMLIFrameElement>;
                            $gameIFrame
                                .attr('src', '')
                                    .parent()
                                    .css('border-color', '');
                            testFinishedHandler($gameIFrame);
                            this.SortGamesByCompletion();
                        })
                    .appendTo($gameIFrameParent)
                    .on(DomMouseEvent.CLICK, (event: JQuery.Event<HTMLDivElement, null>) =>
                    {
                        const gameFrameClass: string = 'game-frame';
                        let $element: JQuery<HTMLElement> = $(event.target);

                        if (!$element.hasClass(gameFrameClass))
                        {
                            $element = $element.parent(`.${gameFrameClass}`);
                        }

                        ToggleClass($element, 'enlarge');
                    });
        }

        this.gameIFrameContext.$Element.hide();
        return this.moveToTest(concurrentTests).AddClassToGameContainer('test');
    }

    public UpdateResolution(): this
    {
        return this.setResolution(this._resolutions[this.Resolution]);
    }

    public TriggerEventOnGames(event: string): this
    {
        const $gameIFrameParent: JQuery<HTMLElement> = this.gameIFrameContext.$Element.parent();

        const $frames: JQuery<HTMLElement> = $gameIFrameParent.find('.game-frame iframe');
        $($frames[0]).trigger(event);

        $frames.each((index: number, element: HTMLElement) =>
        {
            const frame: HTMLElement = $frames[index + 1];
            if (!frame) { return; }

            $(element).one(ClientEvents.GAME_LOADED, () => $(frame).trigger(event));
        });

        return this;
    }

    public ForEachVisible(fnOnEach: (element: IGameInfoVM) => void): this
    {
        this.gameInfos.forEach(gameInfo =>
        {
            if (!gameInfo.IsVisible()) { return; }
            fnOnEach(gameInfo);
        });
        return this;
    }

    public SortGamesByCompletion(): this
    {
        const $gameInfoParent: JQuery<HTMLElement> = this.gameInfos[0].$GameInfo.parent();
        const preSorted: HTMLElement[] = this.gameInfos.map(gameInfo => gameInfo.$GameInfo[0]);

        const gameInfoArray: HTMLElement[]
            = this.gameInfos
                    .sort((a: IGameInfoVM, b: IGameInfoVM) =>
                    {
                        if (a.IsComplete && !b.IsComplete) { return -1; }
                        if (b.IsComplete && !a.IsComplete) { return 1; }
                        if (a.IsComplete && b.IsComplete) { return this.sortLastUpdateName(a, b); }

                        if (a.IsActive && !b.IsActive) { return -1; }
                        if (b.IsActive && !a.IsActive) { return 1; }

                        let delta: number = 0;

                        delta = b.Progress - a.Progress;
                        if (delta) { return delta; }

                        delta = this.sortLastUpdateName(a, b);

                        return delta;
                    })
                    .map(gameInfo => gameInfo.$GameInfo[0]);

        if (preSorted.some((element, index) => preSorted[index] !== gameInfoArray[index]))
        {
            $(gameInfoArray).detach().appendTo($gameInfoParent);
        }
        return this;
    }

    private moveToTest(concurrentTests: number): this
    {
        const $scrollContent: JQuery<HTMLElement> = this._$testGameContainer.show().find('.scroll-content');

        this.gameInfos[0].$GameInfo
                            .parent()
                                .detach()
                                .prependTo($scrollContent);

        let userCount: number = 1;

        if (this.UserId.indexOf(Constants.DEFAULT_TEMPLATE) > -1)
        {
            const calculatedCount: number = Math.floor(concurrentTests / this.GameCount);
            if (calculatedCount > userCount) { userCount = calculatedCount; }
        }

        this.gameInfos.forEach(gameInfoVM =>
        {
            gameInfoVM.ToggleTestUI(true);
            gameInfoVM.UserCount = userCount;
        });

        this.updateGameInfoScrollWidth();
        return this;
    }

    private setResolution(width: number): this
    {
        if (!width) { return this; }

        this.SetFrame(width, width / 1.5);
        this.OnGameLoadedOnce(() => this.ResetGameFrame());
    }

    private updateGameInfoScrollWidth(): this
    {
        const $scrollContent: JQuery<HTMLElement> = this._$testGameContainer.find('.scroll-content');

        let width: number = 30;
        this.ForEachVisible(gameInfo => width += gameInfo.$GameInfo.outerWidth(true));
        $scrollContent.width(width);
        return this;
    }

    private sortLastUpdateName(a: IGameInfoVM, b: IGameInfoVM): number
    {
        if (a.LastUpdate === 0 && b.LastUpdate > 0) { return 1; }
        if (b.LastUpdate === 0 && a.LastUpdate > 0) { return -1; }

        let delta: number = a.LastUpdate - b.LastUpdate;
        if (delta) { return delta; }

        if (a.GameName > b.GameName) { delta = 1; }
        else if (a.GameName < b.GameName) { delta = -1; }

        return delta;
    }

    // #endregion

    // #region Event Helpers

    private showEventMessage(): void
    {
        this.validateEventUi();

        const last: number = this._eventDatas.length - 1;
        const current: number = last + this._eventIndex;
        const count: number = current + 1;

        let page: string;
        let value: string;

        if (count > 0)
        {
            page = count.toString();
            value = pStringify(this._eventDatas[current]);
        }
        else { page = value = ''; }

        withValue(this.eventContainerContext.$Element, $container =>
        {
            $container.find('.page-number').html(page);
            $container.find('#game-events').val(value);
        });
    }

    private updateMessageIndex(offset: number): void
    {
        const length: number = this._eventDatas.length - 1;
        if (length < 0) { return; }

        this._eventIndex += offset;
        this._eventIndex = Math.min(this._eventIndex, 0);
        this._eventIndex = Math.max(this._eventIndex, -length);

        this.showEventMessage();
    }

    private downloadEvents(): void
    {
        this._downloadHelper
                .SetMimeType(MimeType.JSON)
                .AddTimestamp()
                .Download('Events', [pStringify(this._eventDatas)]);
    }

    private postMessage($stringifyCheckbox: JQuery<HTMLInputElement> = null): void
    {
        const $sendOnlyElements = this.eventSendContext.$Element;
        if (!$sendOnlyElements) { return; }

        const isVisible: boolean = $sendOnlyElements.is(':visible');
        if (!isVisible)
        {
            $sendOnlyElements.show();
            return;
        }

        const sendValue: string = $sendOnlyElements.val() as string;
        if (isNullOrEmpty(sendValue)) { return; }

        const contentWindow: Window = this.gameIFrameContext.$Element[0].contentWindow;

        let data: Object;

        if (isChecked($stringifyCheckbox))
        {
            contentWindow.postMessage(sendValue, '*');
            data = sendValue;
        }
        else
        {
            data = this.getEventData(sendValue);
            if (data['error'] == null)
            {
                contentWindow.postMessage(data, '*');
            }
        }

        this.addEventMessage({ Post_Message: data });
    }

    private closeEventSend(): void
    {
        this.eventSendContext.$Element.hide();
    }

    private clearEvents(): void
    {
        this._eventDatas.length = 0;
        this._eventIndex = 0;
        this.showEventMessage();
    }

    private validateEventUi(): void
    {
        const isEmpty: boolean = this._eventDatas.length === 0;
        const atLast: boolean = this._eventIndex === 0;
        const atFirst: boolean = Math.abs(this._eventIndex) === this._eventDatas.length - 1;

        withValue(this.eventContainerContext.$Element.find('button'), $buttons =>
        {
            $($buttons[0]).prop('disabled', isEmpty || atFirst);
            $($buttons[1]).prop('disabled', isEmpty || atLast);
            $($buttons[2]).prop('disabled', isEmpty);
            $($buttons[3]).prop('disabled', isEmpty);
        });
    }

    // #endregion
}

let BUILD_TEMPLATE: string =
`{
    "branch": "{game.branch}",
    "submodules":
    {
        "assets/Library": "release/v{lib.major}",
        "assets/resources/Playbar": "release/v{lib.major}"
    }
}`;
