/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import IMonitor from "./IMonitor";

export default class Monitor implements IMonitor
{
    private _pulseData: any[] = [];
    private _pulse: number = 0;
    public get CurrentPulse(): number { return this._pulse; }

    private _lastPulse: number = 0;

    constructor()
    {
        this.resetPulse();

        this._pulse = this._lastPulse = Monitor.getRandom();
    }

    private resetPulse(): Monitor
    {
        this._lastPulse = this._pulse;
        return this;
    }

    public get IsAlive(): boolean
    {
        return this._lastPulse !== this._pulse;
    }

    public Pulse(pulseObject: any = null): Monitor
    {
        this._pulse = Monitor.getRandom();

        if (this._pulseData.indexOf(pulseObject) < 0)
        {
            this._pulseData.push(pulseObject);
        }
        return this;
    }

    public HasPulsed(pulseObject: any): boolean
    {
        return this._pulseData.indexOf(pulseObject) > -1;
    }

    public ClearPulseData(): Monitor
    {
        this._pulseData.length = 0;
        return this;
    }

    public Kill(clearPulseData: boolean = true): Monitor
    {
        if (clearPulseData) { this.ClearPulseData(); }
        return this.resetPulse();
    }

    private static getRandom(): number { return Math.random() * 0xffffffff; }
}
