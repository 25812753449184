/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import Decorators from "./Decorators";

export default class ContextMenu
{
    public static readonly DATA_ID_PROPERTY: string = 'data-id';

    private _menu: HTMLElement;
    private _target: HTMLElement;

    private _elementMap: Map<string, HTMLElement> = new Map();
    private _validators: (() => boolean)[] = [];

    private _offset: number = 10;

    private _isActive: boolean = false;

    constructor(target: HTMLElement, menu: HTMLElement)
    {
        Decorators.PerformBinding(this);
        this._target = target;

        this._menu = menu;
        menu.classList.add('no-select');
        menu.oncontextmenu = e => e.preventDefault();

        const menuItems: HTMLElement[] = Array.from(menu.querySelectorAll('.item'));

        menuItems.forEach(menuItem =>
            {
                const id: string = menuItem.getAttribute(ContextMenu.DATA_ID_PROPERTY);
                if (!id || this._elementMap.has(id)) { return; }
                this._elementMap.set(id, menuItem);
            });

        target.oncontextmenu = this.contextMenuHandler;
    }

    public AddValidator(validator: () => boolean): this
    {
        this._validators.push(validator);
        return this;
    }

    public OnItemClick(id: string, fnOnItem: (e: Event) => any): this
    {
        const menuItem: HTMLElement = this._elementMap.get(id);
        if (!menuItem) { return this; }

        menuItem.addEventListener('click', e =>
        {
            fnOnItem(e);
            this.mouseLeaveHandler(null);
        });
        return this;
    }

    public SetOffset(value: number): this
    {
        this._offset = value;
        return this;
    }

    @Decorators.BindThis()
    private contextMenuHandler(e: MouseEvent): void
    {
        e.preventDefault();
        if (this._isActive) { return; }

        for (const validator of this._validators)
        {
            if (validator() === false) { return; }
        }

        this.showElement(e, this._menu.style);

        setTimeout(() =>
        {
            this._menu.addEventListener('mouseleave', this.mouseLeaveHandler);
        });
    }

    @Decorators.BindThis()
    private mouseLeaveHandler(e: MouseEvent): any
    {
        this.hideElement(this._menu.style);
        this._menu.removeEventListener('mouseleave', this.mouseLeaveHandler);
    }

    private showElement(e: MouseEvent, style: CSSStyleDeclaration): void
    {
        style.position = 'absolute';
        style.display = 'block';
        style.left = `${e.pageX - this._offset}px`;
        style.top = `${e.pageY - this._offset}px`;
        this._isActive = true;
    }

    private hideElement(style: CSSStyleDeclaration): void
    {
        style.display = 'none';
        this._isActive = false;
    }
}
