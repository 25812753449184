/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import * as $ from 'jquery';

import TypeStrings from '../../../../common/TypeStrings';
import { DomInputEvent, DomMouseEvent } from '../../JUtility/JUtil';
import Decorators from '../Decorators';
import LoggableBase from '../Log/LoggableBase';
import UrlHelper from '../UrlHelper';
import IContextual from './IContextual';

export type InputType = string | string[] | number | boolean;

export default abstract class InputObservableBase<T extends InputType, E extends HTMLElement>
    extends LoggableBase
    implements IContextual
{
    public static readonly CHANGED_EVENT: string = `${InputObservableBase.name}.OnChanged`;
    public static readonly INPUT_ACTIVITY_EVENT: string = `${InputObservableBase.name}.OnInputActivity`;

    public get $Parent(): JQuery<E>
    {
        if (!this.$rootElement) { return null; }
        return this.$rootElement.parent() || this.$rootElement;
    }

    private _value: InputType = null;
    public get Value(): T
    {
        if (this.HasValue()) { return this._value as T; }
        if (this._defaultValue != null) { return this._defaultValue as T; }
        return this._value as T;
    }
    public set Value(value: T) { this.SetValue(value); }
    public HasValue(): boolean { return this._value != null && this._value !== ''; }

    private _lastValue: InputType = null;

    protected urlParameterKey: string = null;
    protected $rootElement: JQuery<E> = null;

    protected isEnabled: boolean = true;

    private _$context: JQuery<HTMLElement> = null;
    public HasContext(): boolean { return this._$context != null; }

    private _rootElementTag: string = null;

    private _defaultValue: InputType = null;
    private _localStorageKey: string = null;
    private _onFocusSelect: boolean = false;

    constructor(elementTag: string, options: IInputObservableOptions<T> = {})
    {
        super('InputObservableBase');

        this._rootElementTag = elementTag;
        this._defaultValue = options.DefaultValue;
        this._localStorageKey = options.LocalStorageKey;

        this.urlParameterKey = options.UrlParameterKey;
        if (options.OnFocusSelect) { this._onFocusSelect = true; }
    }

    public SetElement(elementTag: string): InputObservableBase<T, E>
    {
        this.removeListeners();

        this.$rootElement = (this._$context ? this._$context.find(elementTag) : $(elementTag)) as JQuery<E>;

        if (!this.$rootElement)
        {
            this.logger.Error(`No elemented with tag [${elementTag}]`);
            return this;
        }

        if (this.urlParameterKey)
        {
            const value: T =  this.getUrlParameterValue();
            if (value != null) { this.SetValue(value as T); }
        }

        if (this._value == null)
        {
            if (this.setValueFromLocalStorage()._value == null)
            {
                this.SetValue(this._defaultValue as T);
            }
        }

        if (this._onFocusSelect)
        {
            this.$rootElement.on('focusin', this.onFocusHandler);
        }

        this.configureListeners();
        return this;
    }

    public SetContext($element: JQuery<HTMLElement>): this
    {
        this._$context = $element;
        this.SetElement(this._rootElementTag)
            .inputActivityEventHandler();

        if (!this.HasValue() && this._defaultValue != null) { this.setValue(this._defaultValue, true); }
        return this;
    }

    public Clear(): void
    {
        this.SetValue(null);
    }

    public Toggle(to: boolean = null): InputObservableBase<T, E>
    {
        if (!this.$rootElement) { return this; }

        if (to === null)
        {
            this.$rootElement.toggle();
            return this;
        }

        if (to)
        {
            this.$rootElement.show();
        }
        else
        {
            this.$rootElement.hide();
        }
        return this;
    }

    public OnChanged(fnOnChange: (newValue: T) => void, execute: boolean = true): this
    {
        this.$rootElement.on(InputObservableBase.CHANGED_EVENT, () => fnOnChange(this.Value));
        if (execute) { fnOnChange(this.Value); }
        return this;
    }

    public OnInputActivity(fnOnInput: () => void): this
    {
        this.$rootElement.on(InputObservableBase.INPUT_ACTIVITY_EVENT, fnOnInput);
        return this;
    }

    public OnMouseEvent(event: DomMouseEvent, fnOnEvent: (arg: JQuery.Event) => void): this
    {
        if (!this.$rootElement) { return this; }
        this.$rootElement.on(event, fnOnEvent);
    }

    public SetValue(input: T): InputObservableBase<T, E>
    {
        if (!this.isEnabled) { return this; }

        this.setValue(input);
        this.setElementValue();
        return this;
    }

    public SetEnabled(to: boolean): void
    {
        this.isEnabled = to;
        this.setEnabled(to);
    }

    protected abstract extractValue($element: JQuery<HTMLElement>): T;

    protected setEnabled(to: boolean): void
    {
        if (!this.$rootElement) { return; }
        this.$rootElement.prop('disabled', !to);
    }

    protected getUrlParameterValue(): T
    {
        return UrlHelper.Instance.Parameter(this.urlParameterKey) as T;
    }

    protected configureListeners(): void
    {
        if (!this.$rootElement) { return; }
        this.$rootElement
                .on(DomInputEvent.KEYUP, this.inputActivityEventHandler)
                .on(DomInputEvent.CHANGE, this.inputActivityEventHandler);
    }

    protected removeListeners(): void
    {
        if (!this.$rootElement) { return; }
        this.$rootElement
                .off(DomInputEvent.KEYUP, this.inputActivityEventHandler)
                .off(DomInputEvent.CHANGE, this.inputActivityEventHandler);
    }

    @Decorators.BindThis()
    protected inputActivityEventHandler(event: JQuery.Event = null): void
    {
        const $target: JQuery<HTMLElement> = this.getEventTarget(event);

        let value: InputType = this.extractValue($target);
        if (typeof(value) === TypeStrings.String) { value = (value as string).trim(); }

        this.logger.Log(`Input [${$target.attr('id') || $target.attr('class')}] is [${value}].`);

        this.setValue(value);
        this.$rootElement.trigger(InputObservableBase.INPUT_ACTIVITY_EVENT);
    }

    protected onFocusHandler(event: JQuery.Event = null): void
    {
        $(this).trigger('select');
    }

    protected setValue(input: InputType, ignoreStorage: boolean = false): void
    {
        if (this._value === input) { return; }

        this._lastValue = this._value;
        this._value = input;

        if (!this.$rootElement) { return; }
        if (this._localStorageKey && !ignoreStorage)
        {
            if (input != null)
            {
                localStorage.setItem(this._localStorageKey, input.toString());
            }
            else
            {
                localStorage.removeItem(this._localStorageKey);
            }
        }
        this.$rootElement.trigger(InputObservableBase.CHANGED_EVENT);
    }

    protected setElementValue(): void
    {
        this.$rootElement.val(this.HasValue() ? this._value.toString() : null);
    }

    protected getEventTarget(event: JQuery.Event): JQuery<HTMLElement>
    {
        return event
            ? $(event.target) as JQuery<HTMLElement>
            : this.$rootElement;
    }

    private setValueFromLocalStorage(): InputObservableBase<T, E>
    {
        if (!this._localStorageKey) { return this; }

        const storedValue: T = localStorage.getItem(this._localStorageKey) as T;

        if (!storedValue)
        {
            this.logger.Warn(`No value found for storage key: [${this._localStorageKey}].`);
            return this;
        }

        return this.SetValue(storedValue);
    }
}

export interface IInputObservableOptions<T>
{
    LocalStorageKey?: string;
    UrlParameterKey?: string;
    DefaultValue?: T;
    OnFocusSelect?: boolean;
    OnClick?: (e: JQuery.Event) => any;
}
