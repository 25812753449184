/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import * as $ from 'jquery';
import { camelCase } from 'jquery';

import ITestResult from '../../../common/ITestResult';
import { pStringify } from '../../../common/Misc';
import { TestStatus } from '../../../common/TestStatus';
import TestingService from '../Server/TestingService';
import ContextMenu from '../Utility/ContextMenu';
import Decorators from '../Utility/Decorators';
import DownloadHelper from '../Utility/File/DownloadHelper';
import { MimeType } from '../Utility/MimeType';
import CheckBoxObservable from '../Utility/Observable/CheckBoxObservable';
import { HTMLContextElement } from '../Utility/Observable/ContextElement';
import InputNumberObservable from '../Utility/Observable/InputNumberObservable';
import InputObservable from '../Utility/Observable/InputObservable';
import StringHelper from '../Utility/StringHelper';
import ITestResultVM from './ITestResultVM';
import ViewModelBase from './ViewModelBase';

export default class TestResultsVM extends ViewModelBase implements ITestResultVM
{
    private _keySearchObservable: InputObservable
        = new InputObservable('#find-key',
        {
            UrlParameterKey: 'KEY',
            OnFocusSelect: true
        });

    private _autoRefreshObservable: CheckBoxObservable
        = new CheckBoxObservable('#auto-refresh',
        {
            UrlParameterKey: 'REFRESH',
            DefaultValue: false
        });

    private _refreshIntervalObservable: InputNumberObservable
        = new InputNumberObservable('#refresh-interval',
        {
            UrlParameterKey: 'INTERVAL',
            DefaultValue: 3,
            OnFocusSelect: true
        });

    private _limitObservable: InputNumberObservable
        = new InputNumberObservable('#limit',
        {
            UrlParameterKey: 'LIMIT',
            DefaultValue: 500,
            OnFocusSelect: true
        });

    private _intervalOptionsContext: HTMLContextElement = new HTMLContextElement('.interval-option');

    private _resultsContainerContext: HTMLContextElement = new HTMLContextElement('.results-container');
    private _findButtonContext: HTMLContextElement = new HTMLContextElement('#find-button');
    private _titleElement: HTMLElement;

    private _testingService: TestingService;
    private _downloader: DownloadHelper = new DownloadHelper();
    private _buttonClickTime: number = 0;
    private _inProgress: boolean = false;

    private _results: ITestResult[] = [];
    private _resultCount: number = 0;
    private _lastUpdate: number = 0;
    private _lastSearch: string = '';

    private _intervalId: any;
    private _fnCsvSanitize: (input: string) => string;
    private _fnFileNameSanitize: (input: string) => string;

    constructor(context: HTMLElement = null, testingService: TestingService)
    {
        super(context, 'TestResultsVM');

        this._testingService = testingService;

        this._fnCsvSanitize = StringHelper.Instance.SanitizeCsv;
        this._fnFileNameSanitize = StringHelper.Instance.SantizeFileName;

        this.configureContext();

        const menu: HTMLElement = $('#test-result-context-menu')[0];
        const resultContainer: HTMLElement = this._resultsContainerContext.$Element[0];
        new ContextMenu(resultContainer, menu)
            .AddValidator(() => this._results.length > 0)
            .OnItemClick('csv', () =>
            {
                const newLine: string = '\n';
                const csvContent: string[] = [Object.keys(this._results[0]).join()];

                this._results.forEach(result =>
                    {
                        const row: string[] = [];
                        Object.keys(result).forEach(key => row.push(this._fnCsvSanitize(result[key])));
                        csvContent.push(row.join(','));
                    });

                this._downloader
                        .SetMimeType(MimeType.CSV)
                        .Download(this.getFilename(), [csvContent.join(newLine)]);
            })
            .OnItemClick('json', () =>
            {
                this._downloader
                        .SetMimeType(MimeType.JSON)
                        .Download(this.getFilename(), [pStringify(this._results)]);
            });
    }

    protected onContext($context: JQuery<HTMLElement>): void
    {
        this._titleElement = this._resultsContainerContext.$Element.children()[0];

        this.configureListeners();
    }

    private configureListeners(): void
    {
        this._findButtonContext.$Element.click(this.findButtonClickHandler);

        this._keySearchObservable.OnChanged(this.validate);

        this._autoRefreshObservable
                .OnChanged(this.onAutoRefreshChangedHandler)
                .OnChanged(this.setRefreshInterval);

        this._refreshIntervalObservable.OnChanged(this.setRefreshInterval);
    }

    @Decorators.BindThis()
    private setRefreshInterval(): void
    {
        if (this._intervalId != null)
        {
            clearInterval(this._intervalId);
            this._intervalId = null;
        }
        if (!this._autoRefreshObservable.Value) { return; }
        this._intervalId = setInterval(() => this.updateHandler(), this.getRefreshInterval());
    }

    private getRefreshInterval(): number
    {
        return this._refreshIntervalObservable.Value * 1000;
    }

    private updateHandler(): void
    {
        if (!this._lastSearch || !this._autoRefreshObservable.Value || this._inProgress) { return; }

        this._testingService
                .GetTestResults(this._lastSearch, this._limitObservable.Value, this._lastUpdate)
                .then(results =>
                {
                    if (!results || results.length < 1) { return; }

                    this.setResult(results);
                })
                .catch(err => null);
    }

    @Decorators.BindThis()
    private onAutoRefreshChangedHandler(to: boolean): void
    {
        if (to)
        {
            this._intervalOptionsContext.$Element.show();
        }
        else
        {
            this._intervalOptionsContext.$Element.hide();
        }
    }

    @Decorators.BindThis()
    private findButtonClickHandler(event: JQuery.Event): void
    {
        const search: string = this._lastSearch = this._keySearchObservable.Value;
        const lastClickTime: number = this._buttonClickTime = Date.now();
        this._inProgress = true;

        const always: Function = () => this._inProgress = false;

        this.setResult();
        this._testingService
                .GetTestResults(this._keySearchObservable.Value, this._limitObservable.Value)
                .then(results =>
                {
                    if (this._buttonClickTime !== lastClickTime || search !== this._lastSearch) { return; }

                    results.forEach(result => this.addResult(result));
                    always();
                })
                .catch(err =>
                    {
                        this._resultsContainerContext.$Element.append('<div>No results.</div>');
                        always();
                    });
    }

    private addResult(result: ITestResult): void
    {
        const classString: string = this.getStatusClassName(result.Status);
        $(this.build(result, classString, ++this._resultCount)).appendTo(this._resultsContainerContext.$Element);
        this._lastUpdate = Math.max(this._lastUpdate, result.LastUpdate || 0);
        this._results.push(result);
    }

    private build(result: ITestResult, className: string, count: number): string
    {
        return `<div class="result ${className}">` +
                    `<div class="tiny">${count}</div>` +
                    `<div class="normal">${result.Key}</div>` +
                    `<div class="short">${result.BetConfigId}</div>` +
                    `<div class="short">${result.PrizeIndex}</div>` +
                    `<div class="short">${result.BranchIndex}</div>` +
                    `<div class="short">${result.ReelStop}</div>` +
                    `<div class="long">${result.BonusList.slice(0, 80)}</div>` +
                    `<div class="normal">${className}</div>` +
                    `<div class="long">${result.Comment}</div>` +
                `</div>`;
    }

    private getFilename(): string
    {
        const search: string = this._fnFileNameSanitize(this._keySearchObservable.Value);
        return `results_${search}`;
    }

    private getStatusClassName(status: TestStatus): string
    {
        switch (status)
        {
            case TestStatus.Error:
                return 'error';
            case TestStatus.TimeOut:
                return 'timeout';
            case TestStatus.Skipped:
                return 'skipped';
            case TestStatus.Complete:
                return 'complete';
            case TestStatus.Started:
                return 'in-progress';
            default:
                return 'uknown';
        }
    }

    private setResult(results: ITestResult[] = null): void
    {
        this._resultCount = 0;
        this._results.length = 0;

        this._lastUpdate = 0;

        this._resultsContainerContext.$Element.children().remove();
        this._resultsContainerContext.$Element.append($(this._titleElement).clone());

        if (!results) { return; }
        results.forEach(result => this.addResult(result));
    }

    @Decorators.BindThis()
    private validate(): void
    {
        this._findButtonContext.$Element.prop('disabled', !this._keySearchObservable.HasValue());
    }
}
