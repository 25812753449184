/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import * as $ from 'jquery';

import EndPoints from '../../../common/EndPoints';
import ITestResult from '../../../common/ITestResult';
import ITestStatusParamas from '../../../common/ITestStatusParams';
import ITestUserParams from '../../../common/ITestUserParams';
import ServiceBase from './ServiceBase';

export default class TestingService extends ServiceBase
{
    constructor(hostUrl: string)
    {
        super('TestingService', hostUrl);
    }

    public GetUsers(baseName: string, amount: number): Promise<string[]>
    {
        return this.getUsersRequest(baseName, amount);
    }

    public SetUserIndex(baseName: string, index: number): Promise<string[]>
    {
        return this.setUserIndex(baseName, index);
    }

    public GetTestResults(key: string, limit: number, lastUpdate: number = null): Promise<ITestResult[]>
    {
        return this.getTestResults(key, limit, lastUpdate);
    }

    public UpdateTestResult(param: ITestStatusParamas): Promise<void>
    {
        let error: Error;
        if (param.ReelStop == null)
        {
            error = new Error('Invalid params, Reel Stop Required.');
        }
        if (param.BranchIndex == null)
        {
           error = new Error('Invalid params, BranchIndex Required.');
        }
        return error ? Promise.reject(error) : this.updateTestResult(param);
    }

    public DeleteTestResults(key: string): Promise<void>
    {
        return this.deleteTestResults(key);
    }

    private getUsersRequest(baseName: string, amount: number): Promise<string[]>
    {
        return new Promise((resolve, reject) =>
        {
            const testUsersParams: ITestUserParams =
            {
                BaseName: baseName,
                Amount: amount
            };

            $.post(`${this.hostUrl}${EndPoints.GET_TEST_USERS}`, testUsersParams,
                result => this.resultHandler(result, resolve, reject))
            .fail((req, status, err) => this.failHandler(status, err, reject));
        });
    }

    private setUserIndex(baseName: string, index: number): Promise<string[]>
    {
        return new Promise((resolve, reject) =>
        {
            const testUsersParams: ITestUserParams =
            {
                BaseName: baseName,
                Amount: index
            };

            $.post(`${this.hostUrl}${EndPoints.SET_USER_INDEX}`, testUsersParams,
                result => this.resultHandler(result, resolve, reject))
            .fail((req, status, err) => this.failHandler(status, err, reject));
        });
    }

    private getTestResults(key: string, limit: number, lastUpdate: number = null): Promise<ITestStatusParamas[]>
    {
        return new Promise((resolve, reject) =>
        {
            const param: ITestStatusParamas =
            {
                Key: key,
                PrizeId: -1,
                Status: -1,
                Limit: limit,
                LastUpdate: lastUpdate
            };

            $.post(`${this.hostUrl}${EndPoints.GET_TEST_RESULTS}`, param,
                result => this.resultHandler(result, resolve, reject))
            .fail((req, status, err) => this.failHandler(status, err, reject));
        });
    }

    private updateTestResult(param: ITestStatusParamas): Promise<void>
    {
        return new Promise((resolve, reject) =>
        {
            $.post(`${this.hostUrl}${EndPoints.UPDATE_TEST_STATUS}`, param,
                result => this.resultHandler(result, resolve, reject))
            .fail((req, status, err) => this.failHandler(status, err, reject));
        });
    }

    private deleteTestResults(key: string): Promise<void>
    {
        return new Promise((resolve, reject) =>
        {
            $.post(`${this.hostUrl}${EndPoints.DELETE_TEST_RESULTS}`, { Key: key },
                result => this.resultHandler(result, resolve, reject))
            .fail((req, status, err) => this.failHandler(status, err, reject));
        });
    }
}
