/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import * as $ from 'jquery';

import TypeStrings from '../../common/TypeStrings';
import ProgressiveService from './Server/ProgressiveService';
import LoggableBase from './Utility/Log/LoggableBase';
import IGameInfoVM from './ViewModel/IGameInfoVM';
import IUserOptionVM from './ViewModel/IUserOptionVM';
import IUserTestOptionVM from './ViewModel/IUserTestOptionVM';
import ProgressiveMeterVM from './ViewModel/ProgressiveMeterVM';
import UserTestOptionVM from './ViewModel/UserTestOptionVM';
import CookieHelper from './Utility/CookieHelper';

export default class GameList extends LoggableBase
{
    public LogKey: symbol = Symbol(GameList.name);
    private _userOptions: IUserOptionVM = null;
    private cookies = new CookieHelper();

    constructor(baseUrl: string, userOptions: IUserOptionVM)
    {
        super('GameList');

        //login cookie check
        let cookieUser = this.cookies.getCookie('login');
        if (cookieUser == '') {
            window.location.href = `${baseUrl}/`;
        }

        this._userOptions = userOptions;
        this._userOptions.AssignGameInfoClick(this.gameInfoClickHandler.bind(this));

        if (baseUrl.includes('localhost')) { baseUrl = 'https://lannister.everi-interactive.com'; }

        const proggressiveService: ProgressiveService = new ProgressiveService(`${baseUrl}/gateway/progressive`);
        const meterClass: string = 'progressive-meter';

        const $meters: JQuery<HTMLElement> = $(document.body).find(`.${meterClass}`);
        $.each($meters, (index: number, meter: HTMLElement) =>
            new ProgressiveMeterVM(meter, userOptions, proggressiveService, meterClass));
    }

    private gameInfoClickHandler(gameInfo: IGameInfoVM, frameTargetName: string | JQuery<HTMLElement>): void
    {
        if (!gameInfo) { return; }
        this.Launch(gameInfo.GameURL, frameTargetName);
    }

    public Launch(gameUrl: string, frameTargetName: string | JQuery<HTMLElement>, updateUI: boolean = true): void
    {
        const params: Object =
        {
            userId: this._userOptions.UserId,
            platformId: this._userOptions.PlatformId,
            operatorId: this._userOptions.OperatorId,
            jurisdictionId: this._userOptions.JurisdictionId
        };

        if (this._userOptions instanceof UserTestOptionVM)
        {
            this.openTestOption(
                this._userOptions as UserTestOptionVM,
                gameUrl,
                params,
                frameTargetName,
                updateUI
            );
            return;
        }

        this.openGameSelf(gameUrl, params);
    }

    private openTestOption(
        userTestOption: IUserTestOptionVM,
        gameUrl: string,
        params: Object,
        frame: string | JQuery<HTMLElement>,
        updateUI: boolean): void
    {
        if (this._userOptions.LaunchUrl)
        {
            const index: number = gameUrl.indexOf('/gateway');
            gameUrl = `${this._userOptions.LaunchUrl}${gameUrl.slice(index + 1, gameUrl.length)}`;
        }

        if (userTestOption.Token)
        {
            Object.assign(params, { token: userTestOption.Token });
        }

        if (!userTestOption.LaunchInIframe)
        {
            this.openGameSelf(gameUrl, params);
            return;
        }
        gameUrl = this.getGameUrl(gameUrl, params);

        this.logger.Log(JSON.stringify(params));

        if (updateUI)
        {
            userTestOption.ToggleGameContainer(true);
            userTestOption.ToggleUserOptions(false);
        }

        if (userTestOption.Resolution)
        {
            userTestOption.UpdateResolution();
        }

        if (typeof(frame) === TypeStrings.String)
        {
            window.open(gameUrl, frame as string);
        }
        else
        {
            const $frame: JQuery<HTMLElement> = frame as JQuery;
            $frame.attr('src', gameUrl);
        }

        if (updateUI)
        {
            window.scrollTo(0, 0);
        }
    }

    private openGameSelf(url: string, params: Object): void
    {
        window.open(this.getGameUrl(url, params), '_self');
    }

    private getGameUrl(gameUrl: string, params: Object): string
    {
        gameUrl = `${gameUrl}?fresh=${Date.now()}&${$.param(params)}`;
        if (this._userOptions.OptionalParams)
        {
            gameUrl = `${gameUrl}${this._userOptions.OptionalParams}`;
        }
        return gameUrl;
    }
}
