import CircularLinkedList from "./List/CircularLinkedList";
import IListItem from "./List/IListItem";

/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */

export default class ArrayCycler<T>
{
    private _items: CircularLinkedList<T>;

    constructor(items: T[])
    {
        this._items = new CircularLinkedList(items);
    }

    public get Next(): T
    {
        const currentItem: T = this._items.First ? this._items.First.Value : null;
        this._items.Advance();
        return currentItem;
    }
}
