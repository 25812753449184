/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import * as $ from 'jquery';

import EndPoints from '../../../common/EndPoints';
import ServiceBase from './ServiceBase';

export default class AccountingService extends ServiceBase
{
    private _accessToken: string = null;

    constructor(hostUrl: string)
    {
        super('AccountingService', hostUrl);
    }

    public Login(username: string, gameName: string): Promise<ILoginResponse>
    {
        return new Promise((resolve, reject) =>
        {
            const loginParams: object =
            {
                username,
                password: 'password',
                gameName
            };

            $.post(`${this.hostUrl}${EndPoints.LOGIN}?${$.param(loginParams)}`,
                (data) =>
                {
                    if (data && data.error) { return reject(new Error(data.error)); }

                    this.logger.Success('LOGIN SUCCESS');
                    this._accessToken = data.accessToken;
                    resolve(data);
                })
            .fail(() =>
            {
                reject(Error('Failed to login.'));
            });
        });
    }

    public GetToken(username: string, gameName: string): Promise<string>
    {
        return new Promise((resolve, reject) =>
        {
            this
                .Login(username, gameName)
                .then((data) =>
                {
                    if (data && data.error) { return reject(new Error(data.error)); }
                    if (data.accessToken) { resolve(data.accessToken); }
                    reject(Error('No token received.'));
                }, err => reject(Error('Error fetching token.')));
        });
    }

    public GetBalance(username: string, gameName: string): Promise<IBalanceResponse>
    {
        return new Promise((resolve, reject) =>
        {
            this
                .Login(username, gameName)
                .then(() =>
                    {
                        this.getBalanceRequest(username, gameName)
                            .then(data => resolve(data))
                            .catch(error => reject(error));
                    }, () => reject(Error('Error logging in.')));
        });
    }

    private getBalanceRequest(username: string, gameName: string): Promise<IBalanceResponse>
    {
        return new Promise((resolve, reject) =>
        {
            this.post(EndPoints.REQUEST_BALANCE, resolve, reject,
            {
                userId: username,
                accessToken: this._accessToken,
                operatorId: 'operator_everi',
                platformId: 'platform_everi',
                jurisdictionId: 'everi',
                gameVersion: { rgs: '1', major: '0', build: '0', name: gameName }
            });
        });
    }

    public AddBalance(balanceVal: number, username: string, gameName: string): Promise<any>
    {
        return new Promise((resolve, reject) =>
        {
            this
                .Login(username, gameName)
                .then(() =>
                {
                    this.addBalanceRequest(balanceVal)
                        .then(data => resolve(data))
                        .catch(error => reject(error));
                },
                () =>
                {
                    reject(Error('Error logging in.'));
                });
        });
    }

    private addBalanceRequest(balanceMc: number): Promise<any>
    {
        return new Promise((resolve, reject) =>
        {
            const addBalanceParams: Object =
            {
                launchToken: this._accessToken,
                amountInMC: balanceMc
            };

            this.post(`${EndPoints.ADD_BALANCE}?${$.param(addBalanceParams)}`, resolve, reject);
        });
    }
}

export interface ILoginResponse
{
    accessToken: string;
    error?: string;
}

export interface IBalanceResponse
{
    balanceInMC: number;
}
