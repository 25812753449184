/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
export default class EndPoints
{
    public static readonly GAME_LIST: string = '/gamelist';
    public static readonly UPDATE_VERSIONS: string = '/updateVersions';
    // Settings
    public static readonly PORTAL_SETTINGS: string = '/portalSettings';
    // Accounting
    public static readonly LOGIN: string = '/login';
    public static readonly REQUEST_BALANCE: string = '/requestBalance';
    public static readonly ADD_BALANCE: string = '/addBalance';
    // Test
    public static readonly GET_TEST_USERS: string = '/getTestUsers';
    public static readonly SET_USER_INDEX: string = '/setUserIndex';
    public static readonly UPDATE_TEST_STATUS: string = '/updateTestStatus';
    public static readonly GET_TEST_RESULTS: string = '/getTestResults';
    public static readonly DELETE_TEST_RESULTS: string = '/deleteTestResults';
    // Prize Cycle
    public static readonly GET_PRIZE_ID: string = '/getPrizeId';
    public static readonly GET_TOTAL_PRIZE_INDICES: string = '/getTotalPrizeIndices';
    public static readonly GET_PRIZE_TRANSLATOR: string = '/getPrizeTranslator';
    public static readonly GET_PRIZE_REEL_STOP: string = '/getPrizeReelStop';
    public static readonly GET_BONUS_SCRIPT_LISTS: string = '/getBonusScriptLists';
}
