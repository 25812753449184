/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */

export default class TypeStrings
{
    public static readonly Number: string = typeof(0);
    public static readonly String: string = typeof('');
    public static readonly Array: string = typeof([]);
    public static readonly Function: string = typeof(() => null);
    public static readonly Object: string = typeof({});
    public static readonly Undefined: string = typeof((o => o)());
}
