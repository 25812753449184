import Decorators from './Decorators';
/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */

export default class StringHelper
{
    public static get Instance(): StringHelper { return StringHelper.getInstance(); }
    private static _instance: StringHelper;
    private static getInstance(): StringHelper
    {
        return this._instance
            ? this._instance
            : StringHelper.constructInstance();
    }

    private static constructInstance(): StringHelper
    {
        this._instance = new StringHelper();
        Decorators.PerformBinding(this._instance);
        return this._instance;
    }

    public ConverToTimeDisplay(inputMS: number, fixed: number = 1): string
    {
        const output: string[] = [];

        let runningTime: number = inputMS / 1000.0;
        const seconds: number = runningTime % 60;

        runningTime = Math.floor(runningTime / 60);
        const minutes: number = runningTime % 60;

        runningTime = Math.floor(runningTime / 60);
        const hours: number = runningTime % 24;

        runningTime = Math.floor(runningTime / 24);
        const days: number = runningTime;

        if (days > 0)
        {
            output.push(`${days}d`);
        }

        if (hours > 0)
        {
            output.push(`${hours}h`);
        }

        if (minutes > 0)
        {
            output.push(`${minutes}m`);
        }

        if (seconds > 0)
        {
            output.push(`${seconds.toFixed(fixed)}s`);
        }

        return output.join(', ');
    }

    @Decorators.BindThis()
    public Sanitize(input: string, charCache: Object = StringHelper.HTML_CHAR_OBJECT): string
    {
        if (input == null) { return ''; }
        const output: string[] = [];

        [...input.toString().trim()].forEach(c => output.push(charCache[c] || c));
        return output.join('');
    }

    @Decorators.BindThis()
    public SanitizeCsv(input: string): string
    {
        input = this.Sanitize(input, StringHelper.CSV_CHAR_OBJECT);

        if (input.indexOf(',') > -1)
        {
            input = `"${input}"`;
        }

        return input;
    }

    @Decorators.BindThis()
    public SantizeFileName(input: string): string { return this.Sanitize(input, StringHelper.FILE_NAME_CHAR_OBJECT); }

    private static readonly HTML_CHAR_OBJECT: Object =
    {
        '#': '&#35;',
        '&': '&#38;',
        '(': '&#40;',
        ')': '&#41;',
        '.': '&#46;',
        '/': '&#47;',
        '<': '&#60;',
        '=': '&#61;',
        '>': '&#62;',
    };

    private static readonly CSV_CHAR_OBJECT: Object =
    {
        '"': '""',
    };

    private static readonly FILE_NAME_CHAR_OBJECT: Object =
    {
        '<': 'LT',
        '>': 'GT',
        ':': '_',
        '"': "'",
        '/': '_',
        '\\': '_',
        '|': '_',
        '?': '',
        '*': '_',
    };
}
