/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import { withValue } from "./Misc";
import StringBuilder from "./StringBuilder";

export default class RegExpHelper
{
    private _overrideChar: string = '~';
    private _exactMatchChar: string = '"';

    constructor(options: IRegExpHelperOptions = null)
    {
        if (!options) { return; }
        withValue(options.OverrideChar, char => this._overrideChar = char);
        withValue(options.ExactMatchChar, char => this._exactMatchChar = char);
    }

    public GetRegExpArray(names: string, assumeFuzzy: boolean = false): RegExp[]
    {
        names = names.trim();
        if (!names) { return []; }

        const regExpArr: RegExp[] = [];
        const nameArr: string[] = names.split(',');

        nameArr.forEach(name =>
        {
            const expression: string = this.TranspileToRegExp(name, assumeFuzzy);
            if (expression == null) { return; }

            regExpArr.push(new RegExp(expression, 'i'));
            console.log(`Building regEx search... /${expression}/`);
        });

        return regExpArr;
    }

    public TranspileToRegExp(value: string, assumeFuzzy: boolean = false): string
    {
        if (value == null) { return null; }
        value = value.trim();
        if (value === '') { return null; }

        // Fuzzy override.
        if (assumeFuzzy)
        {
            if (value[0] === this._exactMatchChar
                && value[value.length - 1] === this._exactMatchChar)
            {
                return this.TranspileToRegExp(value.slice(1, value.length - 1));
            }
        }
        if (value[0] === this._overrideChar) { return value.slice(1); }

        const wildCard: string = '.*';
        const sb: StringBuilder = new StringBuilder();
        const parts: string[] = value.split('*');

        parts.forEach((part, index) =>
        {
            part = part.trim();
            if (part === '') { return; }
            if (index === 0 && !assumeFuzzy) { sb.append('^'); }

            sb.append(escapeRegExp(part));

            const next: string = parts[index + 1];
            if (next === '') { return; }
            if (next != null)
            {
                sb.append(wildCard);
                return;
            }

            if (!assumeFuzzy) { sb.append('$'); }
        });

        return sb.toString();
    }
}

/** https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping */
export function escapeRegExp(value: string): string
{
    return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function testRegExpArr(value: string, arr: RegExp[]): boolean
{
    let isValid: boolean = true;

    for (const regExp of arr)
    {
        if (regExp == null) { continue; }
        if (regExp.test(value)) { return true; }
        isValid = false;
    }

    return isValid;
}

export interface IRegExpHelperOptions
{
    OverrideChar?: string;
    ExactMatchChar?: string;
}
