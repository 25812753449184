/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import { PostJson } from '../JUtility/JUtil';
import { IProgressiveAdvertisement } from './IProgressiveAdvertisement';
import ServiceBase from './ServiceBase';

export default class ProgressiveService extends ServiceBase
{
    constructor(hostUrl: string)
    {
        super('ProgressiveService', hostUrl);
    }

    public GetMeterConfig(platformId: string, operatorId: string, gameName: string): Promise<IProgressiveAdvertisement>
    {
        return new Promise((resolve, reject) =>
        {
            this.getMeterConfig(platformId, operatorId, gameName)
                    .then(data =>
                    {
                        resolve(data);
                    })
                    .catch(error => reject(error));
        });
    }

    private getMeterConfig(platformId: string, operatorId: string, gameName: string): Promise<any>
    {
        return new Promise((resolve, reject) =>
        {
            const params: Object =
            {
                platformId,
                operatorId,
                gameName
            };

            PostJson(`${this.hostUrl}/advertiseMeters`, params,
                data =>
                {
                    resolve(data);
                }, () => this.failHandler(415, 'ERRORRRR', reject));
        });
    }
}
