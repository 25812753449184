/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import LoggableBase from '../Utility/Log/LoggableBase';
import { LogColor } from '../Utility/Log/Logger';
import Monitor from '../Utility/Monitor';
import StringHelper from '../Utility/StringHelper';
import IBaseCriteria from '../ViewModel/IBaseCriteria';
import IGameInfoVM from '../ViewModel/IGameInfoVM';
import { ClientEvents } from './ClientEvents';
import GameActions from './GameActions';
import IMessageDetail from './IMessageDetail';
import TestManagerBase from './TestManagerBase';

export default abstract class TestInstanceBase extends LoggableBase
{
    public static readonly TestFinishedEventName: string = 'everi.test.finished';
    public static readonly ERROR_NOT_RESPONDING: Error = new Error('Game is not responding.');

    private static readonly _spinDelaySS: number = 3;
    private static readonly _gameTimeoutSS: number = 120;
    private static readonly _loadedTimeoutSS: number = 15;

    protected parent: TestManagerBase = null;

    private _userId: string = '';

    public get GameName(): string { return this.gameInfo ? this.gameInfo.GameName : ''; }
    public get TestName(): string
    {
        return TestInstanceBase.GetTestName({ UserId: this.UserId, GameName: this.GameName });
    }

    public static GetTestName(criteria: IBaseCriteria): string
    {
        return criteria.UserId
            ? `${criteria.GameName}-${criteria.UserId}`
            : criteria.GameName;
    }

    public get UserId(): string
    {
        return this._userId;
    }

    protected gameInfo: IGameInfoVM = null;

    protected errors: Error[] = [];
    private _errorCount: number = 0;
    public get ErrorCount(): number { return this._errorCount; }

    private _gameLoaded: boolean = false;
    private _isFirstWager: boolean = true;

    private _responseMonitor: Monitor = new Monitor();
    private _advancePlayMonitor: Monitor = new Monitor();
    private _wagerRequestedMonitor: Monitor = new Monitor();

    private _bonusStartMonitor: Monitor = new Monitor();
    private _bonusSpinMonitor: Monitor = new Monitor();

    protected testCount: number = 5;
    protected testsRemaining: number = 0;
    protected get currentTestNumber(): number { return this.testCount + 1 - this.testsRemaining; }

    protected dtMod: number = 1;

    protected $gameIFrame: JQuery<HTMLIFrameElement> = null;
    protected gameLocation: string = null;

    protected color: LogColor = LogColor.Green;

    // #region State Info

    protected isRunning: boolean = false;
    public get IsRunning(): boolean { return this.isRunning; }

    protected hasFinished: boolean = false;
    public get HasFinished(): boolean { return this.hasFinished; }

    // #endregion

    protected startTime: number = 0;
    private _runningBalance: number = 0;
    private _startingBalance: number = 0;

    constructor(
        parent: TestManagerBase,
        gameInfo: IGameInfoVM,
        options: ITestInstanceOption)
    {
        super('TestPlayInstance');

        this.parent = parent;
        this.gameInfo = gameInfo;

        if (options)
        {
            if (options.Color != null) { this.color = options.Color; }
            if (options.TestCount != null) { this.testCount = options.TestCount; }
            if (options.UserId != null) { this._userId = options.UserId; }
            if (options.DtMod != null) { this.dtMod = options.DtMod; }
        }

        this.gameLocation = window.location.origin;
    }

    public BeginTest($gameIFrame: JQuery<HTMLIFrameElement>): this
    {
        this.startTime = Date.now();
        this.$gameIFrame = $gameIFrame;

        this.gameInfo.SetColor(this.color).SetActive(true);
        $gameIFrame.parent().css('border-color', this.color);

        this.success('Beginning test.');

        this.isRunning = true;
        this.hasFinished = false;

        const fullGameLocation: string = `${this.gameLocation}/gateway/gamehost/${this.GameName}/`;

        this.parent.GameList.Launch(fullGameLocation, $gameIFrame.attr('name'), false);
        this.logger.Log(`Full path: [ ${fullGameLocation} ] Container: [ ${$gameIFrame.attr('name')} ].`);

        this.pulseCheckDelayed();
        this.testsRemaining = this.testCount;

        return this;
    }

    protected endTest(): void
    {
        const logColor: LogColor = this._errorCount > 0 ? LogColor.Red : LogColor.Green;
        this.gameInfo.SetColor(logColor).SetActive(false).SetComplete(true);

        const eslapsed: number = Date.now() - this.startTime;
        const timeDisplay: string = StringHelper.Instance.ConverToTimeDisplay(eslapsed);

        // tslint:disable-next-line:max-line-length
        this.success(`PLAY SUMMARY. WIN: [${this._runningBalance - this._startingBalance}] credit(s), TIME: [${timeDisplay}].`);
        this.gameInfo.SetDisplayMessage(`END: ${timeDisplay}.`);

        if (this._errorCount)
        {
            this.error(`Test completed with ${this._errorCount} error(s).`, false);
        }
        else
        {
            this.success('Test completed without errors.');
        }

        this.hasFinished = true;
        this.isRunning = false;

        this.$gameIFrame.trigger(TestInstanceBase.TestFinishedEventName);
    }

    public MessageHandler(event: MessageEvent): void
    {
        if (!this.isRunning) { return; }

        let detail: IMessageDetail;

        try
        {
            detail = JSON.parse(event.data);
        }
        catch (error)
        {
            detail = { id: ClientEvents.ERROR, data: null, errorMessage: error.message };
        }

        if (detail.id && detail.id !== ClientEvents.ERROR) { this._responseMonitor.Pulse(detail.id); }

        switch (detail.id)
        {
            case ClientEvents.GAME_LOADED:
                this.onGameLoadedHandler(detail);
                break;
            case ClientEvents.ADVANCE_PLAY:
                this.success(detail.id);
                this._advancePlayMonitor.Pulse();
                break;
            case ClientEvents.WAGER_REQUESTED:
                this.onWagerRequestedHandler(detail);
                break;
            case ClientEvents.WAGER:
                this.onWagerHandler(detail);
                break;
            case ClientEvents.INSUFFICIENT_FUND:
                detail.errorMessage = ClientEvents.INSUFFICIENT_FUND;
                this.testsRemaining = 0;
            case ClientEvents.ERROR:
                this.onErrorMessageHandler(detail);
                break;
            case ClientEvents.RESULT:
                this.onResultMessageHandler(detail);
                break;
            case ClientEvents.USER_INPUT_PROMPT:
                this.performInteract(detail.id);
                break;
            case ClientEvents.BONUS_PICK_PROMPT:
                this.performPick(detail.id);
                break;
            case ClientEvents.BONUS_START:
                this.success(detail.id);
                this.gameInfo.SetDisplayMessage(detail.id);
                this._bonusStartMonitor.Pulse(detail.id);
                break;
            case ClientEvents.BONUS_SPIN:
            case ClientEvents.BONUS_PICK_AWARD:
                this.onBonusSpinMessageHandler(detail);
                break;
            case ClientEvents.BONUS_END:
                this.onBonusEndMessageHandler(detail);
                break;
        }
        this.pulseCheckDelayed();
    }

    // #region Message Handlers

    private onGameLoadedHandler(detail: IMessageDetail): void
    {
        this.gameInfo.SetProgress(.00004);
        this.success(detail.id);
        this.performSpinDelayed();
        this.pulseCheckDelayed(TestInstanceBase._loadedTimeoutSS);

        this.$gameIFrame.trigger(detail.id);

        try
        {
            this.getContentWindow().onerror = (message: string) =>
            {
                this.error(message);
                return true;
            };
        }
        catch (error) { /* */ }
    }

    private onResultMessageHandler(detail: IMessageDetail): void
    {
        this.success(`${detail.id} ${this.currentTestNumber} Received`
            + ` - BALANCE: [${detail.data.balance}] BET: [${detail.data.bet}] WIN: [${detail.data.win}].`);

        this.updateProgress();
        this.updateRunningBalance(detail);

        this.testsRemaining--;

        if (this.testsRemaining < 1) { this.endTest(); }
        else { this.performSpin(); }
    }

    private onWagerRequestedHandler(detail: IMessageDetail): void
    {
        if (!this._advancePlayMonitor.IsAlive)
        {
            if (this._gameLoaded || !this._isFirstWager)
            {
                this.missingMessageError(ClientEvents.ADVANCE_PLAY);
            }
        }

        this.success(detail.id);
        this._wagerRequestedMonitor.Pulse();
    }

    private onWagerHandler(detail: IMessageDetail): void
    {
        this._advancePlayMonitor.Kill();

        if (!this._wagerRequestedMonitor.IsAlive)
        {
            this.missingMessageError(ClientEvents.WAGER_REQUESTED);
        }
        this._wagerRequestedMonitor.Kill();

        if (this._runningBalance)
        {
            this._runningBalance -= detail.data.bet;
        }
        this._isFirstWager = false;
    }

    private onErrorMessageHandler(detail: IMessageDetail): void
    {
        this.error(`Error: [${detail.errorMessage}}].`);
        this.updateProgress(-1);
        this.endTest();
    }

    private onBonusSpinMessageHandler(detail: IMessageDetail): void
    {
        this.success(detail.id);
        this.gameInfo.SetDisplayMessage(detail.id);
        this.checkBonusStart();
        this._bonusSpinMonitor.Pulse();
    }

    private onBonusEndMessageHandler(detail: IMessageDetail): void
    {
        this.checkBonusStart();
        this.success(detail.id);
        this.gameInfo.SetDisplayMessage(detail.id);

        if (!this._bonusSpinMonitor.IsAlive)
        {
            this.missingMessageWarn(ClientEvents.BONUS_SPIN);
        }

        this._bonusStartMonitor.Kill();
        this._bonusSpinMonitor.Kill();
    }

    private checkBonusStart(): void
    {
        if (this._bonusStartMonitor.IsAlive) { return; }

        this.missingMessageError(ClientEvents.BONUS_START);
        this._bonusStartMonitor.Pulse();
    }

    // #endregion

    private updateRunningBalance(detail: IMessageDetail): void
    {
        if (!detail || !detail.data || detail.data.balance === undefined) { return; }

        if (!this._runningBalance)
        {
            this._startingBalance = this._runningBalance = detail.data.balance || 0;
        }
        else
        {
            let newRunningBalance: number = this._runningBalance + detail.data.win;
            if (Math.round(newRunningBalance * 100) !== Math.round(detail.data.balance * 100))
            {
                this.error(`Balance mismatch.`
                    + ` Expected: [${newRunningBalance}] Actual: [${detail.data.balance}]`);
            }
            this._runningBalance = detail.data.balance;
        }
    }

    private updateProgress(offset: number = 0): void
    {
        this.setProgress((this.currentTestNumber + offset) / this.testCount);
    }

    private setProgress(to: number): void
    {
        if (!this.gameInfo) { return; }
        this.gameInfo.SetProgress(to, this._errorCount < 1);
    }

    private performSpinDelayed(
        overrideMessage: string = null,
        timeSS: number = TestInstanceBase._spinDelaySS): void
    {
        setTimeout(() => this.performSpin(overrideMessage), timeSS * 1000);
    }

    protected abstract performSpin(overrideMessage?: string): void;
    protected abstract performInteract(overrideMessage?: string): void;

    protected sendSpinMessage(): void
    {
        const contentWindow: Window = this.getContentWindow();
        if (!contentWindow) { return this.error('No content window.'); }
        contentWindow.postMessage({ action: GameActions.ACTION_SPIN, dtMod: this.dtMod }, '*');
    }

    protected sendPickMessage(): void
    {
        const contentWindow: Window = this.getContentWindow();
        if (!contentWindow) { return this.error('No content window.'); }
        contentWindow.postMessage({ action: GameActions.ACTION_PICK, dtMod: this.dtMod }, '*');
    }

    private performPick(overrideMessage: string = null): void
    {
        const message: string = overrideMessage || `Pick ${this.currentTestNumber} of ${this.testCount}.`;
        this.gameInfo.SetDisplayMessage(message);

        this.success(message);

        const contentWindow: Window = (this.$gameIFrame as JQuery<HTMLIFrameElement>)[0].contentWindow;
        if (!contentWindow)
        {
            this.error('Could not find content window.');
            this.endTest();
            return;
        }
        this.sendPickMessage();
    }

    private _lastTimeout: number = null;
    protected pulseCheckDelayed(delaySS: number = TestInstanceBase._gameTimeoutSS): void
    {
        this._responseMonitor.Kill();
        if (this._lastTimeout)
        {
            clearTimeout(this._lastTimeout);
        }

        this._lastTimeout = setTimeout(() => this.pulseCheck(),  delaySS * 1000) as any;
    }

    private pulseCheck(): void
    {
        if (this.hasFinished) { return; }

        if (this._responseMonitor.IsAlive)
        {
            this._responseMonitor.Kill();
            return;
        }

        this.error(TestInstanceBase.ERROR_NOT_RESPONDING);
        this.endTest();
    }

    protected success(message: string): void
    {
        this.parent.Success(message, this.color, this);
    }

    protected error(message: string | Error, includeInCount: boolean = true): void
    {
        let messageString: string;
        let error: Error;

        if (message instanceof Error)
        {
            error = message as Error;
            messageString = error.message;
        }
        else
        {
            error = new Error(message);
            messageString = message as string;
        }

        if (includeInCount)
        {
            this.errors.push(error);
            this._errorCount++;
        }
        this.parent.Error(messageString, this);
        this.updateProgress(-1);
    }

    protected updateSpinMessage(message: string = null): void
    {
        message = message || `Spin ${this.currentTestNumber} of ${this.testCount}.`;
        this.gameInfo.SetDisplayMessage(message);
        this.success(message);
    }

    protected getContentWindow(): Window
    {
        return this.$gameIFrame[0].contentWindow;
    }

    private missingMessageWarn(event: ClientEvents): void
    {
        this.success(`**WARNING**: Missing ${event} message.`);
    }

    private missingMessageError(event: ClientEvents): void
    {
        this.error(`Missing ${event} message.`);
    }
}

interface ITestInstanceOption
{
    Color: LogColor;
    TestCount: number;
    UserId: string;
    DtMod?: number;
}
