/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import IListItem from './IListItem';
import LinkedList from './LinkedList';

export default class CircularLinkedList<T> extends LinkedList<T>
{
    constructor(items: T[])
    {
        super(items);

        this.ensureCircular();
    }

    public Add(item: T): this
    {
        super.Add(item);
        return this.ensureCircular();
    }

    protected remove(item: IListItem<T>, before: IListItem<T>, after: IListItem<T>): this
    {
        super.remove(item, before, after);
        return this.ensureCircular();
    }

    protected add(item: IListItem<T>, before: IListItem<T>, after: IListItem<T>): this
    {
        super.add(item, before, after);
        return this.ensureCircular();
    }

    private ensureCircular(): this
    {
        this.last.Next = this.first;
        return this;
    }
}
