/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import InputObservableBase from './InputObservableBase';

export default class SelectObservable<T extends string | number> extends InputObservableBase<T, HTMLSelectElement>
{
    public SetElement(elementTag: string): SelectObservable<T>
    {
        super.SetElement(elementTag);

        if (!this.Value)
        {
            this.SelectFirst();
        }

        return this;
    }

    public AddOption(option: T, select: boolean = false): SelectObservable<T>
    {
        this.$rootElement.append(`<option value="${option}">${option}</option>`);
        if (select)
        {
            this.SetValue(option);
        }
        return this;
    }

    public RemoveOption(option: T): SelectObservable<T>
    {
        this.$rootElement.find(`option[value="${option}"]`).remove();
        return this;
    }

    public SelectFirst(): SelectObservable<T>
    {
        const firstValue: T = this.$rootElement.find('option:first').val() as T;
        this.SetValue(firstValue);
        return this;
    }

    public ClearOptions(): SelectObservable<T>
    {
        this.$rootElement.find('option').remove();
        return this;
    }

    protected extractValue($element: JQuery<HTMLElement>): T
    {
        return $element.find(':selected').val() as T;
    }

    protected setElementValue(): void
    {
        this.$rootElement.val(this.Value);
    }
}
