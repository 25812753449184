/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import * as $ from 'jquery';
import { DomMouseEvent } from '../../JUtility/JUtil';

import LoggableBase from "../Log/LoggableBase";
import IContextual from "./IContextual";

export default class ContextElement<T extends HTMLElement> extends LoggableBase implements IContextual
{
    private _context: JQuery<T>;

    private _$element: JQuery<T> = $();
    public get $Element(): JQuery<T> { return this._$element; }

    private _selector: string;

    constructor(selector: string)
    {
        super('ContextElement');

        this._selector = selector;
    }

    public HasContext(): boolean
    {
        return this._context != null;
    }

    public SetContext($element: JQuery<T>): this
    {
        this._context = $element;
        this._$element = $element.find(this._selector) as JQuery<T>;
        return this;
    }

    public OnClick(fnOnEvent: (ev?: JQuery.Event) => void): this
    {
        return this.AddEventListener(DomMouseEvent.CLICK, fnOnEvent);
    }

    public AddEventListener(event: string, fnOnEvent: (ev: JQuery.Event) => void): this
    {
        if (!this._$element) { return this; }
        this._$element.on(event, fnOnEvent);
    }
}

export class HTMLContextElement extends ContextElement<HTMLElement> { }