/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import { toFixedWhole } from '../../../../common/Misc';
import { MimeType, MimeTypeExtensionMap } from '../MimeType';

export default abstract class FileHelperBase
{
    public static readonly CHAR_SET_UTF8: string = 'charset=utf-8;';

    protected type: string;
    protected extension: string;

    protected includePostFix: boolean = false;

    constructor(mimeType: MimeType = null)
    {
        this.SetMimeType(mimeType);
    }

    public SetMimeType(mimeType: MimeType): this
    {
        this.type = `${mimeType}${FileHelperBase.CHAR_SET_UTF8}`;
        this.extension = this.getExtension(mimeType);
        return this;
    }

    public AddTimestamp(): this
    {
        this.includePostFix = true;
        return this;
    }

    protected getPostFix(): string
    {
        const date: Date = new Date();
        const parts: (string | number)[] =
        [
            '_',
            date.getFullYear(),
            toFixedWhole(date.getMonth(), 2),
            toFixedWhole(date.getDate(), 2),
            date.getTime()
        ];
        return parts.join('');
    }

    private getExtension(mimeType: MimeType): string
    {
        if (!mimeType) { return ''; }
        return MimeTypeExtensionMap.get(mimeType)
            || mimeType.split('/')[1]
            || '';
    }
}
