/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */

export default class Decorators
{
    public static readonly BoundMethodsProperty: string = 'Decorators.bound-methods';

    public static BindThis(): ((target: any, propertyKey: string) => void)
    {
        return (target: any, propertyKey: string) =>
        {
            const boundMethodNames: string[] = target[Decorators.BoundMethodsProperty];
            if (!boundMethodNames)
            {
                target[Decorators.BoundMethodsProperty] = [propertyKey];
                return;
            }

            boundMethodNames.push(propertyKey);
        };
    }

    public static PerformBinding(obj: any): void
    {
        const boundMethodNames: string[] = obj[Decorators.BoundMethodsProperty];
        if (!boundMethodNames || boundMethodNames.length < 1) { return; }
        boundMethodNames.forEach(methodName =>
            {
                if (typeof(obj[methodName]) !== 'function') { return; }
                obj[methodName] = obj[methodName].bind(obj);
            });
        delete obj[Decorators.BoundMethodsProperty];
    }
}
