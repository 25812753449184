/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import TestInstanceBase from './TestInstanceBase';

export default class TestPlayInstance extends TestInstanceBase
{
    protected performSpin(overrideMessage: string = null): void
    {
        this.updateSpinMessage(overrideMessage);
        this.sendSpinMessage();
    }

    protected performInteract(overrideMessage?: string): void
    {
        this.performSpin(overrideMessage);
    }
}
