/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */

type UrlParameterType = string | number | boolean;

export default class UrlHelper
{
    public static get Instance(): UrlHelper { return UrlHelper.getInstance(); }
    private static _instance: UrlHelper = null;
    private static getInstance(): UrlHelper
    {
        return this._instance
            ? this._instance
            : (this._instance = new UrlHelper());
    }

    private _baseUrl: string = '';
    public get BaseUrl(): string
    {
        if (!this._baseUrl) { this._baseUrl = window.location.origin; }
        return this._baseUrl;
    }

    private _cachedParameterValues: Map<string, string | Symbol> = null;

    public Parameter<T extends UrlParameterType>(key: string, value: T = null): T
    {
        if (value != null)
        {
            this.setParameter(key, value);
            return null;
        }
        else
        {
            return this.getParameter(key) as T;
        }
    }

    private getParameter<T extends UrlParameterType>(
        key: string,
        parameterType: UrlParameter = UrlParameter.String): T
    {
        if (!this.CacheParameters()) { return null; }

        const value: any = this._cachedParameterValues.get(key);
        if (value === undefined || value === null) { return null; }

        const stringValue: string = value as string;

        switch (parameterType)
        {
            case UrlParameter.String:
                return (decodeURIComponent(stringValue) || '') as T;
            case UrlParameter.Boolean:
                const lowerCaseValue: string = stringValue.toLowerCase();
                if (lowerCaseValue === 'false') { return false as T; }
                else if (lowerCaseValue === 'true') { return true as T; }
                return null;
            case UrlParameter.Number:
                {
                    try
                    {
                        return Number.parseFloat(stringValue) as T;
                    }
                    catch (error)
                    {
                        return null;
                    }
                }
        }
    }

    public CacheParameters(force: boolean = false): boolean
    {
        if (this._cachedParameterValues && !force) { return true; }
        this._cachedParameterValues = new Map();

        const parameters: string = window.location.search.slice(1);
        if (!parameters) { return false; }

        const parameterStrings: string[] = parameters.split('&');

        for (let parameterString of parameterStrings)
        {
            const paramParts: string[] = parameterString.split('=');
            const value: string = paramParts[1] ? paramParts[1] : '';

            this._cachedParameterValues.set(paramParts[0], value);
        }

        return true;
    }

    private setParameter<T extends UrlParameterType>(key: string, value: T): void
    {
        throw new Error('Not implemented.');
    }
}

enum UrlParameter
{
    Number,
    String,
    Boolean
}
