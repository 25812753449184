/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import * as $ from 'jquery';

import { PageNames } from '../../common/PageNames';
import GameList from './GameList';
import Login from './Login';
import AccountingService from './Server/AccountingService';
import PrizeCycleService from './Server/PrizeCycleService';
import ReportingService from './Server/ReportingService';
import SettingsService from './Server/SettingsService';
import TestingService from './Server/TestingService';
import TestManagerBase, { TestType } from './TestPlay/TestManagerBase';
import TestPlayManager from './TestPlay/TestPlayManager';
import TestPrizeManager from './TestPlay/TestPrizeManager';
import ArrayCycler from './Utility/ArrayCycler';
import { LogColor } from './Utility/Log/Logger';
import UrlHelper from './Utility/UrlHelper';
import IReportVM from './ViewModel/IReportVM';
import ITestOptionVM from './ViewModel/ITestOptionVM';
import ITestResultVM from './ViewModel/ITestResultVM';
import IUserTestOptionVM from './ViewModel/IUserTestOptionVM';
import ReportPortalVM from './ViewModel/ReportPortalVM';
import TestOptionVM from './ViewModel/TestOptionVM';
import TestResultsVM from './ViewModel/TestResultsVM';
import UserOptionVM from './ViewModel/UserOptionVM';
import UserTestOptionVM from './ViewModel/UserTestOptionVM';

export default class Main
{
    private _baseUrl: string;

    public static OnDocumentReady(pageName: string): void
    {
        const main: Main = new Main(UrlHelper.Instance.BaseUrl, pageName);
    }

    constructor(baseUrl: string, pageName: string)
    {
        this._baseUrl = baseUrl;

        this.configureStandardElements();

        switch (pageName)
        {
            case PageNames.LOGIN:
                this.launchLogin();
                return;
            case PageNames.TEST_LIST:
                this.launchTestPortal();
                return;
            case PageNames.REPORT:
                this.launchReportPortal();
                return;
            case PageNames.TEST_RESULTS:
                this.launchTestResults();
                return;
            case PageNames.GAME_LIST:
            default:
                this.launchGamePortal();
                return;
        }
    }

    private configureStandardElements(): void
    {
        try
        {
            document.domain = 'awsrgs.everi-interactive.com';
        } catch (error) { /* */ }

        $('.input-label').click(event =>
        {
            $(event.target).next('input').select();
        });
    }

    private launchGamePortal(): GameList
    {
        return new GameList(this._baseUrl, new UserOptionVM(document.body));
    }

    private launchLogin(): Login
    {
        return new Login(this._baseUrl);
    }

    private launchTestPortal(): void
    {
        const userOptionVM: IUserTestOptionVM  = new UserTestOptionVM(
            document.body,
            new AccountingService(this._baseUrl),
            new SettingsService(this._baseUrl),
            new TestingService(this._baseUrl)
        );
        this.testStartHandler(new GameList(this._baseUrl, userOptionVM), userOptionVM);
    }

    private launchReportPortal(): IReportVM
    {
        return new ReportPortalVM(document.body, new ReportingService(this._baseUrl));
    }

    private launchTestResults(): ITestResultVM
    {
        return new TestResultsVM(
            document.body,
            new TestingService(this._baseUrl)
        );
    }

    private testStartHandler(gameList: GameList, userOptionVM: IUserTestOptionVM): void
    {
        const testType: string = UrlHelper.Instance.Parameter('TEST');
        if (!testType) { return; }

        const testOptionVM: TestOptionVM = new TestOptionVM(document.body, userOptionVM);
        const testPlayManager: TestManagerBase = this.getTestManager(testType, gameList, userOptionVM, testOptionVM);

        switch (testType)
        {
            case TestType.AUTO:
            case TestType.AUTO_PRIZE:
                this.startAutoMode(testPlayManager);
                break;
            case TestType.MANUAL:
            case TestType.PRIZE:
                this.startManualMode(testPlayManager);
                break;
            default:
                return;
        }
    }

    private getTestManager(
        testType: TestType | string,
        gameList: GameList,
        userOptionVM: IUserTestOptionVM,
        testOptionVM: ITestOptionVM): TestManagerBase
    {
        switch (testType)
        {
            case TestType.AUTO:
            case TestType.MANUAL:
                return this.getTestPlayManager(gameList, userOptionVM, testOptionVM);
            case TestType.PRIZE:
            case TestType.AUTO_PRIZE:
                userOptionVM
                    .ShowPrizeOptions()
                    .HideGameOptions();
                return this.getPrizeManager(gameList, userOptionVM, testOptionVM);
            default:
                return null;
        }
    }

    private startAutoMode(testPlayManager: TestManagerBase): void
    {
        testPlayManager
            .SetupTests()
            .BeginTesting();
    }

    private startManualMode(testPlayManager: TestManagerBase): void
    {
        this.onButtonClick('.test-mode', () =>
        {
            testPlayManager.SetupTests();
            this.onButtonClick('.test-start', () => testPlayManager.BeginTesting());
        });
    }

    private onButtonClick(name: string, fnOnTestMode: (event: JQuery.Event) => void): void
    {
        $(name).show().click(event => fnOnTestMode(event));
    }

    private getTestPlayManager(
        gameList: GameList,
        userOptionVM: IUserTestOptionVM,
        testOptionVM: ITestOptionVM): TestPlayManager
    {
        return new TestPlayManager(gameList,
            {
                TestingService: new TestingService(this._baseUrl),
                PrizeService: new PrizeCycleService(this._baseUrl),
                ColorPalette: new ArrayCycler<string>(this.logColors),
                UserOptionVM: userOptionVM,
                TestOptionVM: testOptionVM
            });
    }

    private getPrizeManager(
        gameList: GameList,
        userOptionVM: IUserTestOptionVM,
        testOptionVM: ITestOptionVM): TestPrizeManager
    {
        return new TestPrizeManager(gameList,
            {
                TestingService: new TestingService(this._baseUrl),
                PrizeService: new PrizeCycleService(this._baseUrl),
                ColorPalette: new ArrayCycler<string>(this.logColors),
                UserOptionVM: userOptionVM,
                TestOptionVM: testOptionVM
            });
    }

    private readonly logColors: LogColor[] =
    [
        LogColor.Blue,
        LogColor.Purple,
        LogColor.SlateGray,
        LogColor.Turquoise,
        LogColor.LightOrange,
        LogColor.GreenBlue,
        LogColor.LightBlue,
        LogColor.Mustard
    ];
}

// tslint:disable:no-string-literal
if (window && window['PageName'])
{
    $(document).ready(() => Main.OnDocumentReady(window['PageName']));
}
// tslint:enable:no-string-literal
