/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import UrlHelper from '../UrlHelper';
import InputObservableBase from './InputObservableBase';

export default class CheckBoxObservable extends InputObservableBase<boolean, HTMLInputElement>
{
    protected extractValue($element: JQuery<HTMLElement>): boolean
    {
        return $element.is(':checked');
    }

    protected setElementValue(): void
    {
        if (this.Value)
        {
            this.$rootElement.prop('checked', true);
        }
        else
        {
            this.$rootElement.prop('checked', false);
        }
    }

    protected getUrlParameterValue(): boolean
    {
        const stringValue: string = UrlHelper.Instance.Parameter(this.urlParameterKey);
        if (!stringValue) { return null; }

        if (stringValue.toLowerCase() === 'true') { return true; }
        if (stringValue.toLowerCase() === 'false') { return false; }
        return null;
    }
}
