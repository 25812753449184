/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import ReportingService from '../Server/ReportingService';
import { HTMLContextElement } from '../Utility/Observable/ContextElement';
import InputObservable from '../Utility/Observable/InputObservable';
import RadioGroupObservable from '../Utility/Observable/RadioGroupObservable';
import IReportVM from './IReportVM';
import ViewModelBase from './ViewModelBase';

export default class ReportPortalVM extends ViewModelBase implements IReportVM
{
    private _reportingService: ReportingService;

    private _resolutionObservable: RadioGroupObservable<string>
        = new RadioGroupObservable('#report-type', { GroupName: 'report' });
    public get ReportType(): string { return this._resolutionObservable.Value; }

    // Base Properties
    private _emailObservable: InputObservable
        = new InputObservable('#email', { LocalStorageKey: 'everi.storage.email' });
    public get Email(): string { return this._emailObservable.Value; }
    private _operatorObservable: InputObservable
        = new InputObservable('#operator-id', { LocalStorageKey: 'everi.storage.operatorId' });
    public get Operator(): string { return this._operatorObservable.Value; }

    // Time Properties
    private _startTimeObservable: InputObservable
        = new InputObservable('#start-time',
        {
            LocalStorageKey: 'everi.storage.startTime',
            DefaultValue: '2018-01-01T00:00:00'
        });
    public get StartTime(): number { return (new Date(this._startTimeObservable.Value)).getTime(); }
    private _endTimeObservable: InputObservable
        = new InputObservable('#end-time',
        {
            LocalStorageKey: 'everi.storage.endTime',
            DefaultValue: '2018-01-01T23:59:59'
        });
    public get EndTime(): number { return (new Date(this._endTimeObservable.Value)).getTime(); }
    private _timeZoneObservable: InputObservable
        = new InputObservable('#time-zone', { LocalStorageKey: 'everi.storage.timeZone' });
    public get TimeZone(): string { return this._timeZoneObservable.Value; }

    // User Specific Properties
    private _gameNameObservable: InputObservable
        = new InputObservable('#game-name',
        {
            LocalStorageKey: 'everi.storage.gameName',
            DefaultValue: 'ALL-GAMES'
        });
    public get GameName(): string { return this._gameNameObservable.Value; }
    private _userIdObservable: InputObservable
        = new InputObservable('#user-id', { LocalStorageKey: 'everi.storage.userId' });
    public get UserId(): string { return this._userIdObservable.Value; }

    private _userMessageContext: HTMLContextElement = new HTMLContextElement('.user-message');
    private _userSpaceContext: HTMLContextElement = new HTMLContextElement('hr.space');
    private _sendRequestButtonContext: HTMLContextElement = new HTMLContextElement('#send-request-btn');

    constructor(context: HTMLElement = null, reportingService: ReportingService)
    {
        super(context, 'ReportPortalVM');

        this._reportingService = reportingService;

        this.configureContext();
        this._resolutionObservable.Value = 'OPERATOR';
    }

    protected onContext($context: JQuery<HTMLElement>): void
    {
        this.configureListeners();
    }

    private _$userSpecificElements: JQuery<HTMLElement>[] = [];
    private configureListeners(): void
    {
        this._$userSpecificElements.push(this._gameNameObservable.$Parent);
        this._$userSpecificElements.push(this._userIdObservable.$Parent);
        this._$userSpecificElements.push(this._userSpaceContext.$Element);

        this._resolutionObservable.OnChanged((newValue: string) =>
        {
            if (newValue === 'USER')
            {
                this._$userSpecificElements.forEach($element => $element.show());
            }
            else
            {
                this._$userSpecificElements.forEach($element => $element.hide());
            }
            this.validate();
        });

        this._emailObservable.OnChanged(() => this.validate());
        this._operatorObservable.OnChanged(() => this.validate());

        this._startTimeObservable.OnChanged(() => this.validate());
        this._endTimeObservable.OnChanged(() => this.validate());
        this._timeZoneObservable.OnChanged(() => this.validate());

        this._gameNameObservable.OnChanged(() => this.validate());
        this._userIdObservable.OnChanged(() => this.validate());

        this._sendRequestButtonContext.$Element.click(this.sendRequestButtonClickHandler.bind(this));
    }

    private sendRequestButtonClickHandler(event: JQuery.Event): void
    {
        this._sendRequestButtonContext.$Element.prop('disabled', true);

        const fnAlways: () => void = () => this._sendRequestButtonContext.$Element.prop('disabled', false);

        this._userMessageContext.$Element.text('Requesting report...');
        this._reportingService
            .RequestReport(this)
            .then(() =>
            {
                this._userMessageContext.$Element.text('REQUEST SUBMITTED.');
                fnAlways();
            })
            .catch((error) =>
            {
                this._userMessageContext.$Element.text(`Error requesting report: [${error.message}]`);
                fnAlways();
            });
    }

    private validate(): void
    {
        this._sendRequestButtonContext.$Element.prop('disabled', !this.isValid());
    }

    private isValid(): boolean
    {
        if (!this._emailObservable.Value.trim()) { return false; }
        if (!this._operatorObservable.Value.trim()) { return false; }

        if (!this._startTimeObservable.Value.trim()) { return false; }
        if (!this._endTimeObservable.Value.trim()) { return false; }
        if (!this._timeZoneObservable.Value.trim()) { return false; }

        if (this._resolutionObservable.Value === 'USER')
        {
            if (!this._gameNameObservable.Value.trim()) { return false; }
            if (!this._userIdObservable.Value.trim()) { return false; }
        }

        return true;
    }
}
