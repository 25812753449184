/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import * as $ from 'jquery';

import TestPlayInstance from '../TestPlay/TestPlayInstance';
import Decorators from '../Utility/Decorators';
import { HTMLContextElement } from '../Utility/Observable/ContextElement';
import SelectObservable from '../Utility/Observable/SelectObservable';
import { ITestLogMessage } from './ITestLogMessage';
import ITestOptionVM from './ITestOptionVM';
import IUserTestOptionVM from './IUserTestOptionVM';
import ViewModelBase from './ViewModelBase';

export default class TestOptionVM extends ViewModelBase implements ITestOptionVM
{
    private readonly _bufferAmount: number = 5;
    private readonly _flushIntervalSS: number = 2;
    private readonly _bufferedLogMessages: ITestLogMessage[] = [];

    private _testLogFilter: SelectObservable<string> = new SelectObservable('.log-filter');

    private _logContainerContext: HTMLContextElement = new HTMLContextElement('div.results');
    private _testModeButtonContext: HTMLContextElement = new HTMLContextElement('button.test-mode');
    private _userOption: IUserTestOptionVM = null;

    private _logIndex: number = 0;

    private _gameMessageMap: Map<string, JQuery<HTMLElement>[]> = new Map();
    private _elements: JQuery<HTMLElement>[] = [];

    private _filterOptions: string[] = [];
    private _hasFiltered: boolean = false;

    constructor(context: HTMLElement, userOption: IUserTestOptionVM)
    {
        super(context, 'TestOptionVM');

        this._userOption = userOption;

        if (this._flushIntervalSS > 0)
        {
            setInterval(() => this.flushLogs(), this._flushIntervalSS * 1000);
        }

        this.configureContext();
    }

    protected onContext($context: JQuery<HTMLElement>): void
    {
        this._testLogFilter.OnChanged(this.filterChangedHandler);
    }

    public ToggleLogFilter(to: boolean = null): this
    {
        this._testLogFilter.Toggle(to);
        return this;
    }

    public AddLogMessage(message: ITestLogMessage): this
    {
        this.addFilterOption(TestPlayInstance.GetTestName(message));

        this._bufferedLogMessages.push(message);
        if (this._bufferedLogMessages.length > this._bufferAmount)
        {
            this.flushLogs();
        }
        return this;
    }

    public ScrollLogToBottom(): this
    {
        this._logContainerContext.$Element.scrollTop(this._logContainerContext.$Element[0].scrollHeight);
        return this;
    }

    public ToggleTestModeButton(to: boolean = null): this
    {
        this._testModeButtonContext.$Element.toggle(to);
        return this;
    }

    public LogFilter(gameName: string = null): this | string
    {
        if (gameName === null) { return this._testLogFilter.Value; }

        this._testLogFilter.Value = gameName || '';
        return this;
    }

    @Decorators.BindThis()
    private filterChangedHandler(): this
    {
        const filterValue: string = this._testLogFilter.Value;
        if (!filterValue)
        {
            setTimeout(() => this.showFiltered(), .25 * 1000);
            return this;
        }

        this._userOption.SelectGameInfo(filterValue, true);
        setTimeout(() => this.filterLog(filterValue), .25 * 1000);
        return this;
    }

    private filterLog(filterValue: string = this._testLogFilter.Value): void
    {
        if (!filterValue)
        {
            this.showFiltered();
            return;
        }

        this._elements.forEach($element => $element.addClass('hide'));

        const results: JQuery<HTMLElement>[] = this._gameMessageMap.get(filterValue);
        results.forEach($result => $result.removeClass('hide'));

        this._hasFiltered = true;
    }

    private showFiltered(): void
    {
        if (!this._hasFiltered) { return; }

        this._elements.forEach($element => $element.removeClass('hide'));
        this.ScrollLogToBottom();
        this._hasFiltered = false;
    }

    private addFilterOption(filter: string): this
    {
        if (!filter || this._filterOptions.find(x => x === filter)) { return; }

        this._filterOptions.push(filter);
        this._testLogFilter.AddOption(filter);
        return this;
    }

    private flushLogs(): void
    {
        if (this._bufferedLogMessages.length < 1) { return; }

        const element: HTMLElement = this._logContainerContext.$Element[0];
        const scrolledBottom: boolean = element.scrollHeight - element.scrollTop === element.clientHeight;

        this._bufferedLogMessages.forEach(logMessage => this.addLogAsElement(logMessage));
        this._bufferedLogMessages.length = 0;

        if (scrolledBottom) { this.ScrollLogToBottom(); }
    }

    private addLogAsElement(message: ITestLogMessage): void
    {
        // tslint:disable-next-line:max-line-length
        const $element: JQuery<HTMLElement> = $(`<p style="color:${message.Color};"><span class="index">${this._logIndex++}.</span>${message.Message}</p>`);
        const key: string = TestPlayInstance.GetTestName(message);

        this.addGameMessageMap(key, $element);

        if (this._testLogFilter.Value && this._testLogFilter.Value !== key) { $element.addClass('hide'); }
        this.appendLogMessage($element);
    }

    private addGameMessageMap(messageId: string, $element: JQuery<HTMLElement>): void
    {
        this._elements.push($element);
        const elements: JQuery<HTMLElement>[] = this._gameMessageMap.get(messageId);
        if (elements)
        {
            elements.push($element);
            return;
        }
        this._gameMessageMap.set(messageId, [$element]);
    }

    private appendLogMessage(element: JQuery<HTMLElement>): void
    {
        this._logContainerContext.$Element.append(element);
    }
}
