/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import * as $ from 'jquery';

import IPrizeCycleParams from '../../../common/IPrizeCycleParams';
import { TestStatus } from '../../../common/TestStatus';
import GameList from '../GameList';
import PrizeCycleService from '../Server/PrizeCycleService';
import TestingService from '../Server/TestingService';
import ArrayCycler from '../Utility/ArrayCycler';
import LoggableBase from '../Utility/Log/LoggableBase';
import Logger, { LogColor } from '../Utility/Log/Logger';
import StringHelper from '../Utility/StringHelper';
import UrlHelper from '../Utility/UrlHelper';
import IGameInfoVM from '../ViewModel/IGameInfoVM';
import { ITestLogMessage } from '../ViewModel/ITestLogMessage';
import ITestOptionVM from '../ViewModel/ITestOptionVM';
import IUserTestOptionVM from '../ViewModel/IUserTestOptionVM';
import TestOptionVM from '../ViewModel/TestOptionVM';
import IMessageDetail from './IMessageDetail';
import TestInstanceBase from './TestInstanceBase';

export default abstract class TestManagerBase extends LoggableBase
{
    private _$context: JQuery<HTMLElement> = null;

    protected userOptionVM: IUserTestOptionVM = null;
    public get UserOptionVM(): IUserTestOptionVM { return this.userOptionVM; }
    protected testOptionVM: ITestOptionVM = null;
    public get TestOptionVM(): ITestOptionVM { return this.testOptionVM; }

    private _concurrentTests: number = 5;
    private _currentGameIndex: number = 0;

    private _testInstanceMap: Map<string, TestInstanceBase> = null;
    private _testPlays: TestInstanceBase[] = [];

    private _gameList: GameList = null;
    public get GameList(): GameList { return this._gameList; }

    protected testingService: TestingService = null;
    public get TestingService(): TestingService { return this.testingService; }

    protected colorPalette: ArrayCycler<string> = null;

    private _fnClean: (input: string) => string;

    constructor(gameList: GameList, criteria: ITestManagerCriteria)
    {
        super('TestPlayManager');
        this._gameList = gameList;

        window.addEventListener('message', this.messageRouter.bind(this));
        window.onerror = this.windowErrorHandler.bind(this);

        this._testInstanceMap = new Map();

        this.colorPalette = criteria.ColorPalette;
        this.userOptionVM = criteria.UserOptionVM;
        this.testOptionVM = criteria.TestOptionVM;
        this.testingService = criteria.TestingService;

        this._fnClean = StringHelper.Instance.Sanitize;

        this.fetchUrlParameters();
    }

    public SetupTests(topElement: HTMLElement = null): this
    {
        this._currentGameIndex = 0;

        this.testOptionVM.ToggleTestModeButton(false);
        this._$context = topElement ? $(topElement) : $(document.body);

        this.userOptionVM
                .SelectLaunchIframe()
                .DisableIframeOptions()
                .SetupForTest(this._concurrentTests, this.testFinishedHandler.bind(this));

        return this;
    }

    public abstract BeginTesting(): void;

    public UpdateTestResult(prize: IPrizeCycleParams, status: TestStatus, comment: string = null): void
    {
        setTimeout(() =>
        {
            this.TestingService.UpdateTestResult(
            {
                Key: this.UserOptionVM.TestKey,
                PrizeId: prize.Id,
                BranchIndex: prize.BranchIndex,
                ReelStop: prize.ReelStop,
                Status: status,
                Comment: comment
            });
        });
    }

    protected hideStart(): void
    {
        $('.test-start').hide();
    }

    protected getTotalUsers(): number
    {
        let total: number = 0;
        this.userOptionVM.ForEachVisible(gameInfo => total += gameInfo.UserCount);
        return total;
    }

    private fetchUrlParameters(): void
    {
        try
        {
            const runValue: string = UrlHelper.Instance.Parameter('RUN');
            if (!runValue) { throw new Error('No [RUN] parameter.'); }
            this._concurrentTests = Number.parseInt(runValue, 10) || this._concurrentTests;
        }
        catch (error)
        {
            this.logger.Log('No test count parameter override.');
        }
    }

    private messageRouter(event: MessageEvent): void
    {
        const detail: IMessageDetail = JSON.parse(event.data);

        const gameId: string = detail.data ? detail.data.gameId : null;
        if (!gameId)
        {
            this.logger.Warn(`Invalid gameId: [${gameId}].`);
            return;
        }

        const testName: string = TestInstanceBase.GetTestName({ UserId: detail.data.userId, GameName: gameId });
        const testInstance: TestInstanceBase = this._testInstanceMap.get(testName);

        if (!testInstance)
        {
            this.logger.Error(`No test instance for gameId: [${testName}].`);
            return;
        }

        this.userOptionVM.SortGamesByCompletion();
        testInstance.MessageHandler(event);
    }

    private windowErrorHandler(message: string): boolean
    {
        this.Error(`Window error: [${message}].`);
        return true;
    }

    protected configureGameInfos(usernames: string[] = null): void
    {
        let totalCount: number = 0;

        this.userOptionVM
            .ForEachVisible(gameInfo =>
            {
                gameInfo.ClearSelected()
                        .ToggleTestUI(false);

                const userCount: number = gameInfo.UserCount;
                if (userCount < 1)
                {
                    gameInfo.Toggle(false);
                    return;
                }

                let count: number = 0;
                while (true)
                {
                    const nextUserName: string = usernames ? usernames[totalCount++] : this.userOptionVM.UserId;
                    const testPlay: TestInstanceBase = this.getTestInstance(gameInfo, nextUserName);
                    this._testInstanceMap.set(testPlay.TestName, testPlay);
                    this._testPlays.push(testPlay);

                    gameInfo.Filter = TestInstanceBase.GetTestName(
                        {
                            UserId: nextUserName,
                            GameName: gameInfo.GameName
                        });

                    count++;
                    if (count < userCount)
                    {
                        gameInfo = gameInfo.CloneAfter();

                        this.userOptionVM.AddGameInfo(gameInfo);
                        continue;
                    }
                    break;
                }
            })
            .AssignGameInfoClick((gameInfo: IGameInfoVM) =>
            {
                if (gameInfo.LastUpdate === 0) { return; }

                if (this.testOptionVM.LogFilter() === gameInfo.Filter)
                {
                    gameInfo.ClearSelected();
                    this.testOptionVM.LogFilter('');
                }
                else
                {
                    this.testOptionVM.LogFilter(gameInfo.Filter);
                }
            })
            .ToggleUserOptions(false, true);
    }

    protected triggerFirstTest(): void
    {
        this.userOptionVM.TriggerEventOnGames(TestInstanceBase.TestFinishedEventName);
    }

    protected abstract getTestInstance(gameInfo: IGameInfoVM, username: string): TestInstanceBase;

    private testFinishedHandler($gameIFrame: JQuery<HTMLIFrameElement>): void
    {
        if (this._testPlays.every(testPlay => testPlay.HasFinished))
        {
            this.endTesting();
            return;
        }

        if (this._currentGameIndex > this._testPlays.length - 1) { return; }

        const testName: string = this._testPlays[this._currentGameIndex++].TestName;
        const testInstance: TestInstanceBase = this._testInstanceMap.get(testName);

        if (!testInstance)
        {
            this.logger.Error(`No test instance for gameId: [${testName}].`);
            return;
        }

        const oldUserId: string = this.userOptionVM.UserId;
        this.userOptionVM.UserId = testInstance.UserId;
        testInstance.BeginTest($gameIFrame);
        this.userOptionVM.UserId = oldUserId;

        this.userOptionVM.SortGamesByCompletion();
        return;
    }

    private endTesting(): void
    {
        window.removeEventListener('message', this.messageRouter.bind(this));
        window.onerror = null;

        let errorCount: number = 0;
        this._testPlays.forEach(testPlay => errorCount += testPlay.ErrorCount);

        if (errorCount > 0)
        {
            this.Error(`Tests completed with ${errorCount} error(s).`);
            this._testPlays.forEach(testPlay =>
                {
                    if (testPlay.ErrorCount === 0) { return; }
                    this.Error(`${testPlay.TestName} (${testPlay.ErrorCount}).`);
                }, this);
        }
        else
        {
            this.Log('Tests complete. No errors.');
        }
    }

    // #region Logging Helpers

    public Log(message: string, color: LogColor = LogColor.Green): void
    {
        this.logger.Success(message, color);
        this.visualLog(message, color);
    }

    public Error(
        message: string,
        testInstance: TestInstanceBase = null): void
    {
        const fullMessage: string = `${testInstance ? `[${testInstance.TestName}] - ` : '' }${message}`;
        Logger.Get(testInstance || this).Error(fullMessage);
        this.visualLog(fullMessage, 'red', testInstance);
    }

    public Success(
        message: string,
        color: LogColor = LogColor.Green,
        testInstance: TestInstanceBase = null): void
    {
        const fullMessage: string = `${testInstance ? `[${testInstance.TestName}] - ` : '' }${message}`;
        Logger.Get(testInstance || this).Success(fullMessage, color);
        this.visualLog(fullMessage, color, testInstance);
    }

    private visualLog(message: string, color: string, testInstance: TestInstanceBase = null): void
    {
        const logObject: ITestLogMessage =
        {
            Message: this._fnClean(message),
            Color: color,
            UserId: '',
            GameName: ''
        };

        if (testInstance)
        {
           logObject.UserId = testInstance.UserId;
           logObject.GameName = testInstance.GameName;
        }

        this.testOptionVM.AddLogMessage(logObject);
    }

    // #endregion
}

export enum TestType
{
    AUTO = 'AUTO',
    MANUAL = 'MANUAL',
    PRIZE = 'PRIZE',
    AUTO_PRIZE = 'AUTO_PRIZE',
}

export interface ITestManagerCriteria
{
    TestingService: TestingService;
    PrizeService: PrizeCycleService;
    ColorPalette?: ArrayCycler<string>;
    UserOptionVM: IUserTestOptionVM;
    TestOptionVM: ITestOptionVM;
}
