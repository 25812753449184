/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import TypeStrings from '../../../../common/TypeStrings';

export default interface IContextual
{
    HasContext(): boolean;
    SetContext($element: JQuery<HTMLElement>): this;
}

export function TrySetContext(obj: IContextual, $context: JQuery<HTMLElement>): boolean
{
    if (obj == null
        || typeof(obj.HasContext) !== TypeStrings.Function
        || typeof(obj.SetContext) !== TypeStrings.Function) { return false; }

    obj.SetContext($context);
    return true;
}
