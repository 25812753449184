/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import * as $ from 'jquery';

function ToggleClass($target: JQuery<HTMLElement>, className: string, to: boolean = null): JQuery<HTMLElement>
{
    if ($target.hasClass(className) || to === false)
    {
        $target.removeClass(className);
        return;
    }
    $target.addClass(className);
}

function PostJson(
    url: string,
    data: object,
    success: JQuery.TypeOrArray<JQuery.Ajax.SuccessCallback<any>>,
    fail: () => void): JQuery.jqXHR<any>
{
    return $.ajax(
    {
        headers:
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        type: 'POST',
        url,
        data: JSON.stringify(data),
        dataType: 'json',
        success
    }).fail(fail);
}

enum DomInputEvent
{
    CHANGE = 'change',
    KEYUP = 'keyup'
}

enum DomMouseEvent
{
    AUX_CLICK = 'auxclick',
    CLICK = 'click',
    CONTEXT_MENU = 'contextmenu',
    DBL_CLICK = 'dblclick',
    MOUSE_DOWN = 'mousedown',
    MOUSE_ENTER = 'mouseenter',
    MOUSE_LEAVE = 'mouseleave',
    MOUSE_MOVE = 'mousemove',
    MOUSE_OVER = 'mouseover',
    MOUSE_OUT = 'mouseout',
    MOUSE_UP = 'mouseup',
    POINTER_LOCK_CHANGE = 'pointerlockchange',
    POINTER_LOCK_ERROR = 'pointerlockerror',
    SELECT = 'select',
    WHEEL = 'wheel'
}

function draggable($element: JQuery<HTMLElement>): void
{
    $element.on(DomMouseEvent.MOUSE_DOWN, mouseDownDraggingHandler.bind($element, $('body')));
}

function mouseDownDraggingHandler(this: JQuery<HTMLElement>, $body: JQuery<HTMLBodyElement>, e: MouseEvent): void
{
    const dragInfo: IDragInfo =
    {
        pageX: e.pageX,
        pageY: e.pageY,
        $element: this,
        offset: this.offset()
    };

    function draggingHandler(drageEvent: IPageEvent): void
    {
        var left = dragInfo.offset.left + (drageEvent.pageX - dragInfo.pageX);
        var top = dragInfo.offset.top + (drageEvent.pageY - dragInfo.pageY);
        dragInfo.$element.offset({ top: top, left: left });
    }

    function mouseupHandler(): void
    {
        $body
            .off(DomMouseEvent.MOUSE_MOVE, draggingHandler)
            .off(DomMouseEvent.MOUSE_UP, mouseupHandler);
    }
    
    $body
        .on(DomMouseEvent.MOUSE_UP, mouseupHandler)
        .on(DomMouseEvent.MOUSE_MOVE, draggingHandler);
}

const isChecked = ($e: JQuery<HTMLInputElement>): boolean =>
{
    if (!$e) { return false; }
    
    const input: HTMLInputElement = $e[0];
    if (!input) { return false; }

    return input.checked === true;
    
}

export { DomInputEvent, DomMouseEvent, PostJson, ToggleClass, draggable, isChecked };

interface IPageEvent
{
    pageX: number;
    pageY: number;
}

interface IDragInfo extends IPageEvent
{
    $element: JQuery<HTMLElement>;
    offset: JQuery.Coordinates;
}