/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import IPrizeCycleParams, { IBonusPrize } from '../../../common/IPrizeCycleParams';
import { TestStatus } from '../../../common/TestStatus';
import GameActions from './GameActions';
import TestInstanceBase from './TestInstanceBase';
import TestPrizeManager from './TestPrizeManager';

export default class TestPrizeInstance extends TestInstanceBase
{
    protected performSpin(overrideMessage: string = null): void
    {
        this.updateSpinMessage(overrideMessage);

        setTimeout(() =>
        {
            this.sendPrizeMessage();
            setTimeout(() => this.sendSpinMessage(), 2000);
        }, 500);
    }

    protected performInteract(overrideMessage?: string): void
    {
        this.updateSpinMessage(overrideMessage);
        this.sendSpinMessage();
    }

    protected endTest(): void
    {
        this.updatePrizeStatus(null);
        super.endTest();
    }

    private _lastPrize: IPrizeCycleParams;
    private _lastErrorCount: number = 0;

    private sendPrizeMessage(): void
    {
        const contentWindow: Window = this.getContentWindow();
        if (!contentWindow) { return this.error('No content window.'); }

        const nextPrize: IPrizeCycleParams = (this.parent as TestPrizeManager).GetNextPrizeForce();
        if (!nextPrize)
        {
            this.endTest();
            return;
        }

        this.updatePrizeStatus(nextPrize);

        const bonus: IBonusPrize = nextPrize as IBonusPrize;
        const bonusText: string = bonus.BonusList
            ? bonus.BonusList.toString().slice(0, 25)
            : 'NONE';

        // tslint:disable:max-line-length
        this.success('**** **** PRIZE FORCE **** ****');
        this.success(`Paytable: [ ${nextPrize.PaytableId} ] BetConfigId: [ ${nextPrize.BetConfigId} ]`);
        this.success(`Prize: [ ${nextPrize.PrizeIndex} ] Branch: [ ${nextPrize.BranchIndex} ] Stop: [ ${nextPrize.ReelStop} ]`);
        this.success(`Bonus: [ ${bonusText} ]`);
        this.success('**** **** **** **** **** **** ****');
        // tslint:enable:max-line-length

        this.parent.UpdateTestResult(nextPrize, TestStatus.Started);

        contentWindow.postMessage(
            {
                action: GameActions.ACTION_PRIZE,
                dtMod: this.dtMod,
                prizeForce: nextPrize
            }, '*');
    }

    private updatePrizeStatus(nextPrize: IPrizeCycleParams): void
    {
        const errors: Error[] = this.errors.slice(this._lastErrorCount);
        const errorCount: number = errors.length;

        let status: TestStatus;

        if (errorCount)
        {
            if (this.errors.every(error => error === TestInstanceBase.ERROR_NOT_RESPONDING))
            {
                status = TestStatus.TimeOut;
                (this.parent as TestPrizeManager).AddPrize(this._lastPrize);
            }
            else
            {
                status = TestStatus.Error;
            }
        } else { status = TestStatus.Complete; }

        if (this._lastPrize && this._lastPrize.Id)
        {
            this.parent.UpdateTestResult(this._lastPrize, status);
        }

        this._lastPrize = nextPrize;
        this._lastErrorCount = this.ErrorCount;
    }
}
