/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import * as $ from 'jquery';

import EndPoints from '../../../common/EndPoints';
import IBonusListResponse from '../../../common/IBonusListResponse';
import IPrizeCycleParams from '../../../common/IPrizeCycleParams';
import ServiceBase from './ServiceBase';

export default class PrizeCycleService extends ServiceBase
{
    constructor(hostUrl: string)
    {
        super('PrizeCycleService', hostUrl);
    }

    public GetPrizeId(params: IPrizeCycleParams): Promise<number>
    {
        return new Promise((resolve, reject) => this.getPrizeId(this.hostUrl, params, resolve, reject));
    }

    public GetPrizeIndices(
        gameName: string,
        paytableId: string,
        betConfigId: number): Promise<number>
    {
        const params: IPrizeCycleParams =
        {
            GameName: gameName,
            PaytableId: paytableId,
            BetConfigId: betConfigId,
        };
        return new Promise((resolve, reject) => this.getPrizeIndices(this.hostUrl, params, resolve, reject));
    }

    public GetTranslatorIndex(
        gameName: string,
        paytableId: string,
        betConfigId: number,
        prizeIndex: number): Promise<number>
    {
        const params: IPrizeCycleParams =
        {
            GameName: gameName,
            PaytableId: paytableId,
            BetConfigId: betConfigId,
            PrizeIndex: prizeIndex,
        };
        return new Promise((resolve, reject) => this.getTranslators(this.hostUrl, params, resolve, reject));
    }

    public GetReelStopIndex(
        gameName: string,
        paytableId: string,
        betConfigId: number,
        prizeIndex: number,
        branchIndex: number = -1): Promise<number>
    {
        const params: IPrizeCycleParams =
        {
            GameName: gameName,
            PaytableId: paytableId,
            BetConfigId: betConfigId,
            PrizeIndex: prizeIndex,
            BranchIndex: branchIndex
        };
        return new Promise((resolve, reject) => this.getTotalReelStops(this.hostUrl, params, resolve, reject));
    }

    public GetBonusScripts(
        gameName: string,
        paytableId: string,
        betConfigId: number,
        prizeIndex: number,
        branchIndex: number = -1): Promise<IBonusListResponse>
    {
        const params: IPrizeCycleParams =
        {
            GameName: gameName,
            PaytableId: paytableId,
            BetConfigId: betConfigId,
            PrizeIndex: prizeIndex,
            BranchIndex: branchIndex
        };
        return new Promise((resolve, reject) => this.getBonusScriptLists(this.hostUrl, params, resolve, reject));
    }

    private getPrizeId(
        hostUrl: string,
        data: IPrizeCycleParams,
        resolve: (args: any) => void,
        reject: (args: any) => void): void
    {
        this.postRetry(`${hostUrl}${EndPoints.GET_PRIZE_ID}`, data, resolve, reject);
    }

    private getPrizeIndices(
        hostUrl: string,
        data: IPrizeCycleParams,
        resolve: (args: any) => void,
        reject: (args: any) => void): void
    {
        this.postRetry(`${hostUrl}${EndPoints.GET_TOTAL_PRIZE_INDICES}`, data, resolve, reject);
    }

    private getTranslators(
        hostUrl: string,
        data: IPrizeCycleParams,
        resolve: (args: any) => void,
        reject: (args: any) => void): void
    {
        this.postRetry(`${hostUrl}${EndPoints.GET_PRIZE_TRANSLATOR}`, data, resolve, reject);
    }

    private getTotalReelStops(
        hostUrl: string,
        data: IPrizeCycleParams,
        resolve: (args: any) => void,
        reject: (args: any) => void): void
    {
        this.postRetry(`${hostUrl}${EndPoints.GET_PRIZE_REEL_STOP}`, data, resolve, reject);
    }

    private getBonusScriptLists(
        hostUrl: string,
        data: IPrizeCycleParams,
        resolve: (args: any) => void,
        reject: (args: any) => void): void
    {
        this.postRetry(`${hostUrl}${EndPoints.GET_BONUS_SCRIPT_LISTS}`, data, resolve, reject);
    }

    private postRetry(
        url: string,
        data: Object,
        resolve: Function,
        reject: Function,
        count = 0): void
    {
        $.post(url, data, result => this.resultHandler(result, resolve, reject))
        .fail((req, status, err) =>
        {
            if (count > 500)
            {
                this.failHandler(status, err, reject);
                return;
            }

            setTimeout(() =>
            {
                this.postRetry(url, data, resolve, reject, count++);
            }, 1000);
        });
    }
}
