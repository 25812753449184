/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import { LogColor } from '../Utility/Log/Logger';
import { HTMLContextElement } from '../Utility/Observable/ContextElement';
import InputNumberObservable from '../Utility/Observable/InputNumberObservable';
import IGameInfoVM from './IGameInfoVM';
import ViewModelBase from './ViewModelBase';

export default class GameInfoVM extends ViewModelBase implements IGameInfoVM
{
    public get GameId(): number { return this._serverGameInfo.Id; }
    public get GameName(): string { return this._serverGameInfo.GameName; }
    public get GameURL(): string { return this._serverGameInfo.URL; }
    public get DisplayName(): string { return this._serverGameInfo.DisplayName; }
    public get Version(): string { return this._serverGameInfo.Version; }

    public Filter: string = null;

    private _lastUpdate: number = 0;
    public get LastUpdate(): number { return this._lastUpdate; }

    private _isActive: boolean = false;
    public get IsActive(): boolean { return this._isActive; }
    public SetActive(value: boolean): IGameInfoVM
    {
        this._isActive = value;
        this._lastUpdate = Date.now();
        return this;
    }

    private _isComplete: boolean = false;
    public get IsComplete(): boolean { return this._isComplete; }
    public SetComplete(value: boolean): IGameInfoVM
    {
        this._isComplete = value;
        this._lastUpdate = Date.now();
        return this;
    }

    public get $GameInfo(): JQuery<HTMLElement> { return this.$context; }

    private _gameNameContext: HTMLContextElement = new HTMLContextElement('a');
    private _progressBarContext: HTMLContextElement = new HTMLContextElement('.progress-bar');
    private _progressBGContext: HTMLContextElement = new HTMLContextElement('.progress-background');

    private _testFieldsContext: HTMLContextElement = new HTMLContextElement('.user-info');

    private _testCountObservable: InputNumberObservable = new InputNumberObservable('.user-info input.test-count',
    {
        OnFocusSelect: true
    });
    public get TestCount(): number { return this._testCountObservable.Value; }
    public set TestCount(value: number) { this._testCountObservable.Value = value; }

    private _userCountObservable: InputNumberObservable = new InputNumberObservable('.user-info input.user-count',
    {
        OnFocusSelect: true
    });
    public get UserCount(): number { return this._userCountObservable.Value; }
    public set UserCount(value: number) { this._userCountObservable.Value = value; }

    private _dtModObservable: InputNumberObservable = new InputNumberObservable('.user-info input.game-dtmod',
    {
        OnFocusSelect: true,
        DefaultValue: 500,
        UrlParameterKey: 'DTMOD'
    });
    public get DtMod(): number { return this._dtModObservable.Value; }
    public set DtMod(value: number) { this._dtModObservable.Value = value; }

    private _progress: number = 0;
    public get Progress(): number { return this._progress; }

    private _serverGameInfo: IServerGameInfo = null;

    constructor(context: HTMLElement)
    {
        super(context, 'GameInfoVM');
        this.configureContext();
    }

    protected onContext($context: JQuery<HTMLElement>): void
    {
        this._serverGameInfo = $context.data('gameinfo');
        if (this._serverGameInfo.DtMod != null) { this._dtModObservable.Value = this._serverGameInfo.DtMod; }

        this._gameNameContext.$Element.removeAttr('href');
        this._progressBarContext.$Element.hide();
        this._progressBGContext.$Element.hide();
        this._testFieldsContext.$Element.hide();
    }

    public SetColor(color: LogColor): IGameInfoVM
    {
        if (!this._gameNameContext[0]) { return this; }

        this._gameNameContext.$Element
                .css('background-color', color)
                .parent()
                    .css('border-color', color)
                    .css('box-shadow', `0 0 2em ${color}`);
        return this;
    }

    public SetDisplayMessage(message: string): GameInfoVM
    {
        if (message !== null) { this._gameNameContext.$Element.html(message); }
        return this;
    }

    public SetProgress(progress: number, success: boolean = null): GameInfoVM
    {
        if (progress < 0) { progress = 0; }
        if (progress > 1) { progress = 1; }

        if (this._progress !== progress)
        {
            this._lastUpdate = Date.now();
        }

        this._progress = progress;

        const progressCSS: string = `${((progress < .03 ? .03 : progress) * 100).toFixed(2)}%`;
        const progressDisplay: string = `${(progress * 100).toFixed(2)}%`;

        if (!this._progressBarContext.$Element.is(':visible')) { this.ToggleProgressBar(true); }

        this._progressBarContext.$Element
            .css('width', progressCSS)
            .find('.progress')
                .html(progressDisplay);

        if (success !== null)
        {
            const shouldMoveRight: boolean = progress > .7;
            this._progressBarContext.$Element
                    .css('background-color', success ? LogColor.Green : LogColor.Red)
                    .css('color', shouldMoveRight ? LogColor.White : '')
                    .find('.progress')
                        .css('right', shouldMoveRight ? '0' : '');
        }

        return this;
    }

    public ToggleTestUI(to: boolean = null): GameInfoVM
    {
        to ? this._testFieldsContext.$Element.show() : this._testFieldsContext.$Element.hide();
        return this;
    }

    public ToggleProgressBar(to: boolean = null): GameInfoVM
    {
        this._progressBGContext.$Element.toggle(to);
        this._progressBarContext.$Element.toggle(to);
        return this;
    }

    public Select(): GameInfoVM
    {
        this.$context.addClass('selected');
        return this;
    }

    public IsSelected(): boolean
    {
        return this.$context.hasClass('selected');
    }

    public ClearSelected(): GameInfoVM
    {
        this.$context.removeClass('selected');
        return this;
    }

    public Toggle(override: boolean = null): void
    {
        if (override === null) {  this.$context.toggle(); }
        else if (override)
        {
            this.$context.show();
        }
        else
        {
            this.$context.hide();
        }
    }

    public CloneAfter(): GameInfoVM
    {
        const $gameInfo: JQuery<HTMLElement> =  this.$context.clone();
        $gameInfo.insertAfter(this.$context);

        const newGameInfo: GameInfoVM = new GameInfoVM($gameInfo[0]);
        newGameInfo.TestCount = this.TestCount;
        newGameInfo.UserCount = 1;
        newGameInfo.DtMod = this.DtMod;

        return newGameInfo;
    }
}

interface IServerGameInfo
{
    Id: number;
    GameName: string;
    URL: string;
    DisplayName: string;
    DtMod: number | null;
    Version: string;
}
