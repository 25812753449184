/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import * as $ from 'jquery';

import RegExpHelper, { testRegExpArr } from '../../../common/RegExpHelper';
import { DomMouseEvent, ToggleClass } from '../JUtility/JUtil';
import Decorators from '../Utility/Decorators';
import ContextElement, { HTMLContextElement } from '../Utility/Observable/ContextElement';
import InputObservable from '../Utility/Observable/InputObservable';
import GameInfoVM from './GameInfoVM';
import IGameInfoVM from './IGameInfoVM';
import IUserOptionVM from './IUserOptionVM';
import ViewModelBase from './ViewModelBase';

export default class UserOptionVM extends ViewModelBase implements IUserOptionVM
{
    public get LaunchUrl(): string { return ''; }
    public get UserId(): string { return ''; }
    public get PlatformId(): string { return 'platform_everi'; }
    public get OperatorId(): string { return 'operator_everi'; }
    public get JurisdictionId(): string { return 'everi'; }

    private _gameNameFilterObservable: InputObservable = new InputObservable('#name-filter',
        { UrlParameterKey: 'FILTER' });

    protected $gameInfos: JQuery<HTMLElement>;

    public get GameCount(): number
    {
        return this.gameInfos.filter(gameInfo => gameInfo.IsVisible()).length;
    }

    protected gameInfos: IGameInfoVM[];
    protected gameInfoMap: Map<HTMLElement, IGameInfoVM>;

    protected gameIFrameContext: ContextElement<HTMLIFrameElement> = new ContextElement('#game-frame');

    private _regExpHelper: RegExpHelper = new RegExpHelper();

    constructor(
        context: HTMLElement = null,
        logKey: string = null)
    {
        super(context, logKey || 'UserOptionVM');

        this.gameInfos = [];
        this.gameInfoMap = new Map();

        this.setupGameInfos();
        if (!logKey) { this.configureContext(); }
    }

    protected onContext($context: JQuery<HTMLElement>): void
    {
        this.configure($('p.description'));
    }

    protected configure($descriptions: JQuery<HTMLElement>): void
    {
        if (window.orientation !== undefined) { $(document.body).addClass('mobile'); }

        this._gameNameFilterObservable.OnChanged(this.onFilterChangedHandler);

        $.each($descriptions, (index: number, description: HTMLElement) =>
        {
            const $description: JQuery<HTMLElement> = $(description);

            const descriptionButtonHTML: string = `<button class="description">i</button>`;
            const descriptionString: string = $description.text();
            if (!descriptionString) { return; }

            let textVisible: boolean = false;

            $description
                .html(descriptionButtonHTML)
                .show()
                .on(DomMouseEvent.CLICK, (event) =>
                {
                    event.stopPropagation();

                    if (!textVisible)
                    {
                        $.each($descriptions, (i: number, descriptionElement: HTMLElement) =>
                        {
                            $(descriptionElement).trigger('close').removeClass('open');
                        });
                    }

                    $description.html(textVisible ? descriptionButtonHTML : descriptionString);
                    ToggleClass($description, 'open', !textVisible);
                    textVisible = !textVisible;
                })
                .on('close', () =>
                {
                    $description.html(descriptionButtonHTML);
                    ToggleClass($description, 'open', false);
                    textVisible = false;
                })
                .on(DomMouseEvent.MOUSE_LEAVE, () =>
                {
                    $description.trigger('close');
                });
        });
    }

    public OnSiteChange(fnOnSiteChange: () => void): this
    {
        return this;
    }

    public AddGameInfo(gameInfo: IGameInfoVM): void
    {
        this.gameInfos.push(gameInfo);
        this.gameInfoMap.set(gameInfo.$GameInfo[0], gameInfo);
        this.$gameInfos = this.$gameInfos.add(gameInfo.$GameInfo);
    }

    public AssignGameInfoClick(
        fnOnClick: (
            gameInfo: IGameInfoVM,
            iframe: string | JQuery<HTMLElement>) => void
        ): this
    {
        if (!this.$gameInfos)
        {
            this.setupGameInfos();
        }

        this.$gameInfos.off(DomMouseEvent.CLICK);
        this.$gameInfos.on(DomMouseEvent.CLICK, (event: JQuery.Event) =>
        {
            const $gameInfo: JQuery<HTMLElement> = $(event.target).parent('.game-info') as JQuery<HTMLElement>;
            fnOnClick(this.gameInfoMap.get($gameInfo[0]), this.gameIFrameContext.$Element);
        });

        return this;
    }

    public ForEachVisible(fnOnEach: (element: IGameInfoVM) => void): this
    {
        this.gameInfos.forEach(gameInfo =>
        {
            if (!gameInfo.IsVisible()) { return; }
            fnOnEach(gameInfo);
        });
        return this;
    }

    public DisableFilter(): this
    {
        this._gameNameFilterObservable.SetEnabled(false);
        return this;
    }

    protected setupGameInfos(): void
    {
        this.$gameInfos = this.$context
                .find('.game-info')
                .each((index: number, element: HTMLElement) => { this.addNewInfo(element); });
    }

    protected addNewInfo(element: HTMLElement): IGameInfoVM
    {
        const gameInfoVM: IGameInfoVM = new GameInfoVM(element);
        this.gameInfoMap.set(element, gameInfoVM);
        this.gameInfos.push(gameInfoVM);
        return gameInfoVM;
    }

    @Decorators.BindThis()
    private onFilterChangedHandler(val: string): boolean
    {
        this.logger.Log(`Filtering based on: [${val}].`);

        const regExpArr: RegExp[] = this._regExpHelper.GetRegExpArray(val, true);

        this.gameInfos.forEach(gameInfo =>
        {
            const checks: string[] =
            [
                gameInfo.GameName.toLowerCase(),
                gameInfo.DisplayName.toLowerCase(),
                gameInfo.Version
            ];

            const toggleVal: boolean = checks.some(checkString => testRegExpArr(checkString, regExpArr));
            gameInfo.Toggle(toggleVal);
        });

        return false;
    }

    // #endregion
}
