/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import TypeStrings from '../../../../common/TypeStrings';
import UrlHelper from '../UrlHelper';
import InputObservableBase from './InputObservableBase';

export default class InputNumberObservable extends InputObservableBase<number, HTMLInputElement>
{
    protected extractValue($element: JQuery<HTMLElement>): number
    {
        const val: any = $element.val();
        const valType: string = typeof(val);

        if (val === '') { return null; }

        if (valType === TypeStrings.String)
        {
            return Number.parseFloat(val as string);
        }
        else if (valType === TypeStrings.Number)
        {
            return val as number;
        }
        else
        {
            return val ? (val[0] || '').length : null;
        }
    }

    protected getUrlParameterValue(): number
    {
        try
        {
            const value: number = Number.parseFloat(UrlHelper.Instance.Parameter(this.urlParameterKey));
            if (value == null || isNaN(value))
            {
                return null;
            }
            return value;
        }
        catch
        {
            return null;
        }
    }

    protected setElementValue(): void
    {
        this.$rootElement.val(this.Value);
    }
}
