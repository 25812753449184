/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import { withValue } from "../../../../common/Misc";
import TypeStrings from "../../../../common/TypeStrings";
import FileHelperBase from "./FileHelperBase";

export default class DownloadHelper extends FileHelperBase
{
    public Download(fileName: string, content: BlobPart[]): this
    {
        const blob: Blob = new Blob(content, { type: this.type });

        if (this.includePostFix)
        {
            fileName += this.getPostFix();
            this.includePostFix = false;
        }

        fileName = `${fileName}.${this.extension}`;

        // IE
        if (typeof(navigator.msSaveBlob) === TypeStrings.Function)
        {
            navigator.msSaveBlob(blob, fileName);
            return;
        }

        withValue(document.createElement('a'), link =>
        {
            if (link.download == null) { return; }

            // Browsers that support HTML5 download attribute
            withValue(URL.createObjectURL(blob), url =>
            {
                link.setAttribute('href', url);
                link.setAttribute('download', fileName);
                link.style.visibility = 'hidden';

                withValue(document.body, body =>
                {
                    body.appendChild(link);
                    link.click();
                    body.removeChild(link);
                });
            });
        });

        return this;
    }
}
