/*
 * ////////////////////////////////////////////////////////////////////////////////
 * //
 * // This software system consists of computer software and documentation.
 * // It contains trade secrets and confidential information which are proprietary
 * // to Everi Games Inc.  Its use or disclosure in whole or in part without
 * // the express written permission of Everi Games Inc. is prohibited.
 * //
 * // This software system is also an unpublished work protected under the copyright
 * // laws of the United States of America.
 * //
 * // Copyright © 2022 Everi Games Inc.  All Rights Reserved
 * //
 * ////////////////////////////////////////////////////////////////////////////////
 */
import TypeStrings from '../../../../common/TypeStrings';
import Decorators from '../Decorators';
import ILoggable from './ILoggable';
import Logger, { LoggerInstance } from './Logger';

export default abstract class LoggableBase implements ILoggable
{
    public LogKey: symbol = Symbol();

    private _logger: LoggerInstance = null;
    protected get logger(): LoggerInstance { return this._logger; }

    constructor(c: string)
    {
        let logSymbolString: string;
        if (typeof(c) !== TypeStrings.String)
        {
            logSymbolString = (c as any).constructor.name || this.constructor.name;
        }
        else
        {
            logSymbolString = c as string;
        }

        this.LogKey = Symbol(logSymbolString);
        this._logger = Logger.Get(this);
        Decorators.PerformBinding(this);
    }
}
